import React from 'react'
import ErrorNav from './ErrorNav'
import ProgressBar from './ProgressBar'
import ContactInfoDetails from './ContactInfoDetails'

const ContactInfoSignUp = () => {
  return (
    <>
      <ErrorNav/>
      <ProgressBar/>
      <ContactInfoDetails/>
    </>
  )
}

export default ContactInfoSignUp