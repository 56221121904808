import React from 'react'


import Navbar from './Navbar'
import Footer from './Footer'
import CookiePolicyDetails from './CookiePolicyDetails'

function CookiePolicy() {
  return (
    <React.Fragment>
      <Navbar/>
      <CookiePolicyDetails/>
      <Footer/>
    </React.Fragment>
  )
}

export default CookiePolicy