import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DriverTopNav from "./DriverTopNav";
import DriverSideNav from "./DriverSideNav";
import "../../admin/components/index.css";
import { useAuthentication } from "../../AuthContext";
import { toast } from "react-toastify";

const RedirectToLogin = () => {
	const navigate = useNavigate();
	toast.success("Please Login");
	useEffect(() => {
		// Redirect to /admin-login
		navigate("/login");
	}, [navigate]);

	return null;
};

const DriverDashboard = () => {
	const { isLoggedin } = useAuthentication();
	const location = useLocation();
	const { pathname } = location;

	const [verificationStatus, setVerificationStatus] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Move the declaration of id and token inside the useEffect scope
				const token = localStorage.getItem("token");
				const id = localStorage.getItem("id");

				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const data = response.data.driver;
				// console.log(data)
				// Assuming the API response contains an 'isVerified' field
				const isVerified = data.isVerified;

				// Set verification status based on the API response
				setVerificationStatus(isVerified ? "Verified" : "Not Verified");
			} catch (error) {
				// console.log('Error fetching data from the API', error);
			}
		};

		fetchData();
	}, []); // Removed id and token from the dependency array

	const handleGetVerified = () => {
		// Implement logic to initiate the process for getting verified
		// console.log('Handling Get Verified...');
	};

	const getPageContent = () => {
		switch (pathname) {
			case "/driver":
				return {
					pageTitle: "Dashboard",
					pageDescription: "Just your dashboard",
					verificationStatus: verificationStatus,
				};
			case "/driver/":
				return {
					pageTitle: "Dashboard",
					pageDescription: "Just your dashboard",
					verificationStatus: verificationStatus,
				};
			case "/driver/profile":
				return {
					pageTitle: "Profile",
					pageDescription: "Here are your details",
					verificationStatus: verificationStatus,
				};
			case "/driver/docs":
				return {
					pageTitle: "Documents",
					pageDescription: "Here are your documents",
					verificationStatus: verificationStatus,
				};
			case "/driver/inbox":
				return {
					pageTitle: "Inbox",
					pageDescription: "Check your messages",
					verificationStatus: verificationStatus,
				};
			case "/driver/settings":
				return {
					pageTitle: "Settings",
					pageDescription: "Edit your profile",
					verificationStatus: verificationStatus,
				};
			// Add other cases as needed
			default:
				return {
					pageTitle: "Default Page",
					pageDescription: "Default description",
					verificationStatus: verificationStatus,
				};
		}
	};

	const { pageTitle, pageDescription } = getPageContent();

	return (
		<>
			<DriverTopNav
				pageTitle={pageTitle}
				pageDescription={pageDescription}
				verificationStatus={verificationStatus}
			/>
			<div className="app">
				<DriverSideNav />
				<div className="content">
					<div className="contentout">
						{isLoggedin ? <Outlet /> : <RedirectToLogin />}
					</div>
				</div>
			</div>
			{/* Display "Get Verified" button if verificationStatus is false */}
			{!verificationStatus && (
				<button onClick={handleGetVerified}>Get Verified</button>
			)}
		</>
	);
};

export default DriverDashboard;
