import React, { useState, useEffect } from 'react'
import questions from './faqData'
import FaqContentDetails from './FaqContentDetails'
import Categories from './Categories'

function FaqContent() {
const [contentdetails, setContentDetails] = useState(questions)
useEffect(()=>{
  document.title = 'Rive | Frequently Asked Questions!';
}, [])

const filterItems = (category) => {
  if (category === 'all') {
    setContentDetails(questions);
    return;
  }
  const newItems = questions.filter((item) => item.category === category);

  setContentDetails(newItems);
}

    return (

      <section>
        <Categories filterItems={filterItems}/>
        <FaqContentDetails contentdetails={contentdetails}/></section>
    )
     
    
  }
 

export default FaqContent