import React, {useEffect} from 'react'

function TermsOfUseDetails() {

  useEffect(()=>{
  document.title = 'Rive | Our Terms of Use!';
}, [])

  return (
    <React.Fragment>
      <section>
        <div className="container contact-details">
          <h1>
            Terms of Use
          </h1>

          <h2>
            Last updated: 1st January 2024
          </h2>
          <br />
          
          <ol>
            <li>
              <h2>Acceptance of Terms</h2>
              <p>By accessing or using the services provided by Rive ("we," "us," or "our"), you agree to comply with and be bound by these Terms of Use. If you do not agree with these terms, please do not use our services.</p>
            </li>
            <li>
              <h2>Changes to Terms</h2>
              <p>We reserve the right to modify or update these Terms of Use at any time. You are responsible for checking these terms periodically for changes. Your continued use of our services after the posting of changes constitutes your acceptance of the modified terms.</p>
            </li>
            <li>
              <h2>Use of Services</h2>
              <p>You agree to use our services for lawful purposes and in a manner consistent with all applicable laws and regulations. You may not use our services in a way that could harm our website, our services, or other users.</p>
            </li>
            <li>
              <h2>Intellectual Property</h2>
              <p>All content, logos, trademarks, and intellectual property on our website are the property of Rive or its licensors and are protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from our services without our permission.</p>
            </li>
            <li>
              <h2>Privacy</h2>
              <p>Your use of our services is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>
            </li>
            <li>
              <h2>Limitation of Liability</h2>
              <p>Our services are provided "as is." We make no warranties or representations regarding the accuracy or completeness of the content. We shall not be liable for any direct, indirect, special, or consequential damages arising out of your use of our services.</p>
            </li>
            <li>
              <h2>Termination</h2>
              <p>We reserve the right to terminate or suspend your access to our services at our discretion, without notice, for violations of these Terms of Use or for any other reason.</p>
            </li>
            <li>
              <h2>Links to Third-Party Sites</h2>
              <p>Our services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
            </li>
            <li>
              <h2>Governing Law</h2>
              <p>These Terms of Use are governed by and construed in accordance with the laws of Nigeria.</p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>If you have any questions or concerns about these Terms of Use, please contact us at <a href="mailto:info@rive.com">info@rive.com</a>.</p>
            </li>
          </ol>
        </div>
      </section>
    </React.Fragment>
  )
}

export default TermsOfUseDetails