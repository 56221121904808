import React from 'react'
import logo from '../Images/Logo.svg'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="container footer">
        <Link to='/'><img src={logo} alt="Rive" className="footer-item"/></Link>
        <div className="footer-item">
          <h2>Company</h2>
          <ul>
            <li><Link to='/about-us'> About US</Link></li>
            <li><Link to='/contact'> Contact US</Link></li>
            <li><Link to='/cookie-policy'> Cookie Policies</Link></li>
            <li><Link to='/privacy-policy'> Privacy Policies</Link></li>
          </ul>
        </div>
        <div className="footer-item">
          <h2>Product</h2>
          <ul>
            <li><a href="/#features">Features</a></li>
            <li><Link to='/faq'> FAQ</Link></li>
            <li><Link to='/terms-of-use'> Terms of Use</Link></li>
          </ul>
        </div>
        <div className="footer-item">
          <h2>Download</h2>
          <ul>
            <li><a href="">Download on AppStore</a></li>
            <li><a href="">Download on Playstore</a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer