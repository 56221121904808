import React, { useState, useEffect } from "react";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DriverProfile = ({ handleGetVerified, verificationStatus }) => {
	const [driverData, setDriverData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = localStorage.getItem("token");
				const id = localStorage.getItem("id");

				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const data = response.data.driver;
				// console.log(data);

				setDriverData({
					email: data.email,
					firstname: data.firstName,
					lastname: data.lastName,
					tel: data.tel,
					role: data.role,
				});
			} catch (error) {
				// console.log("Error fetching data from the API", error);
			}
		};

		fetchData();
	}, []);

	if (!driverData) {
		return (
			<div className="loading-spinner">
				<FontAwesomeIcon icon={faSpinner} spin />
			</div>
		);
	}

	return (
		<div className="datapreview">
			<p>
				<strong>Email:</strong> {driverData.email}
			</p>
			<p>
				<strong>First Name:</strong> {driverData.firstname}
			</p>
			<p>
				<strong>Last Name:</strong> {driverData.lastname}
			</p>
			<p>
				<strong>Telephone:</strong> {driverData.tel}
			</p>
			<p>
				<strong>Role:</strong> {driverData.role}
			</p>
			{/* Use handleGetVerified and verificationStatus as needed */}
		</div>
	);
};

export default DriverProfile;
