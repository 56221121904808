import React, {useEffect} from 'react'


import mission from '../Images/Mission.svg'
import vision from '../Images/Vision.svg'
import affordable from '../Images/Afordability.svg'
import reliable from '../Images/Reliability.svg'
import safety from '../Images/Safety.svg'
import accessible from '../Images/a11y.svg'

function AboutContents() {
useEffect(()=>{
  document.title = 'Rive | Your Reliable Logistics Solution in Abeokuta, Ogun State!';
}, [])

  return (
    <React.Fragment>
      <section>
    <div className="color">
      <div className="container">
        <div className="imager">
          <img src={mission} alt="Our Mission" class="mission"/>
        </div>
        <div className="text-description">
          <h1>Our Mission</h1>
          <p>At Rive, we are passionate about transforming the way people and goods move in Abeokuta and beyond. We believe in providing cost-effective and efficient transportation services that cater to the diverse needs of our customers.</p>
        </div>
      </div>

      <div className="container">
        <div className="imager">
          <img src={vision} alt="Our Mission" class="vision"/>
        </div>
        <div className="text-description">
          <h1>Our Vision</h1>
          <p>To become the preferred logistics partner for individuals and businesses in Abeokuta and eventually, the entire nation of Nigeria.</p>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container sets">
      <h1>What sets us apart</h1>
      <div class="cover">
      <div class="feature-wrapper">
        <div class="heading">
          <img src={affordable} alt="affordable"/>
          <h2>Affordability</h2>
        </div>
        <div class="texting">
          We're committed to offering budget-friendly transportation options without compromising on quality and safety.
        </div>
      </div>
      <div class="feature-wrapper">
        <div class="heading">
          <img src={reliable} alt="Reliable"/>
          <h2>Reliability</h2>
        </div>
        <div class="texting">
          You can count on us to get you where you need to be, when you need to be there. We prioritize punctuality and consistency.
        </div>
      </div>
      <div class="feature-wrapper">
        <div class="heading">
          <img src={safety} alt="Safety"/>
          <h2>Safety</h2>
        </div>
        <div class="texting">
          Your safety is our top priority. Our vehicles are well-maintained, and our drivers are skilled and vetted to ensure a secure journey.
        </div>
      </div>
      <div class="feature-wrapper">
        <div class="heading">
          <img src={accessible} alt="accessible"/>
          <h2>Accessibility</h2>
        </div>
        <div class="texting">
          We aim to make logistics accessible to all. Our user-friendly app allows you to book rides with ease.
        </div>
      </div>
      </div>
    </div>
  </section>
    </React.Fragment>
  )
}

export default AboutContents