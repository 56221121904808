import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useAuthentication } from "../AuthContext";

const DriverLoginDetails = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const { setIsLoggedin } = useAuthentication();

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		const userCredentials = {
			email: email,
			password: password,
		};

		try {
			const response = await axios.post(
				"https://api.rive.ng/api/v1/auth/driver/login",
				userCredentials
			);

			// console.log(response.data);
			const token = response.data.token;
			const id = response.data.driver._id;
			localStorage.setItem("token", token);
			localStorage.setItem("id", id);

			// Login successful
			toast.success("Login successful", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			// Navigate to the user verification page or any other desired page
			setIsLoggedin(true);
			navigate("/driver");
		} catch (error) {
			// console.error("Error during login:", error);
			const msg = error?.response?.data?.msg;
			toast.error(msg || "An error occurred during login", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<section className="hero">
				<div className="container contain">
					<div className="hero-text">
						<h1>Drive, Rive, & Make Money Anywhere.</h1>
						<p>
							With our innovative platform, you have the power to take control
							of your journey...
						</p>
					</div>
					<div className="form-wrapper">
						<form onSubmit={handleLogin}>
							<label htmlFor="email">Email address</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="example@user.com"
								autoComplete="on"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<label htmlFor="password">Password</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="Enter password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<p className="daa">
								<Link to={"/forgot-password"}>Forgot Password?</Link>
							</p>
							<button type="submit">
								{loading ? "Logging in..." : "Login as a Driver"}
							</button>
						</form>
						<p className="center">or</p>
						<Link to="/">
							<button className="secondary">Sign Up as a Driver</button>
						</Link>
					</div>
				</div>
				<ToastContainer />
			</section>
		</>
	);
};

export default DriverLoginDetails;
