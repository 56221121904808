import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ContactDetails from './ContactDetails'

function Contact() {
  return (
    <React.Fragment>
      <Navbar/>
      <ContactDetails/>
      <Footer/>
    </React.Fragment>
  )
}

export default Contact