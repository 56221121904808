import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import TermsOfUseDetails from './TermsOfUseDetails'

function TermsOfUse() {
  return (
    <React.Fragment>
      <Navbar/>
      <TermsOfUseDetails/>
      <Footer/>
    </React.Fragment>
  )
}

export default TermsOfUse