import React from 'react';

import Header from './Navbar';
import Footer from './Footer';
import AboutHero from './AboutHero';
import AboutContents from './AboutContents';

function AboutUs() {
  return (
    <React.Fragment>
      <Header/>
      <AboutHero/>
      <AboutContents/>
      <Footer/>
    </React.Fragment>
  );
}

export default AboutUs