import React, { useState } from 'react'
import down from '../Images/arrow-down.svg'

function FaqContentDetails({contentdetails}) {
const [showquestion, setShowquestion] = useState(false)
  return (
    <section className='container'>
      <div className='questionwrapper'>
      {
        contentdetails.map((detail)=>{
          const {id, q, a, category} = detail
            return <article key={id}>
              <div className='question'>
                <div className='quest'>{q} <img src={down} alt="" className='vis' onClick={()=>{
                  setShowquestion(!showquestion)} }/>
                </div>
                {
                showquestion && <p className='questioner'>{a}</p>
                }
              </div>
              
            </article>
        })
      }
      </div>
    </section>
  )
}

export default FaqContentDetails