import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import "./index.css";
import { useAuthentication } from "../../AuthContext";
import { toast } from "react-toastify";

const RedirectToAdminLogin = () => {
	const navigate = useNavigate();
	toast.success("Please Login");
	useEffect(() => {
		// Redirect to /admin-login
		navigate("/admin-login");
	}, [navigate]);

	return null;
};

const Dashboard = () => {
	const { isLoggedin } = useAuthentication();
	// Use React Router's useLocation hook to get information about the current route
	const location = useLocation();
	const { pathname } = location;

	// Function to get dynamic values based on the current route
	const getPageContent = () => {
		switch (pathname) {
			case "/admin":
				return {
					pageTitle: "Dashboard",
					pageDescription: "Just your dashboard",
				};
			case "/admin/":
				return {
					pageTitle: "Dashboard",
					pageDescription: "Just your dashboard",
				};
			case "/admin/users":
				return { pageTitle: "Users", pageDescription: "Just Everybody" };
			case "/admin/drivers":
				return { pageTitle: "Drivers", pageDescription: "Just Drivers" };
			case "/admin/trips":
				return {
					pageTitle: "Trips",
					pageDescription: "Just Movement of People",
				};
			case "/admin/deliveries":
				return {
					pageTitle: "Delieveries",
					pageDescription: "Just Movement of Things",
				};
			case "/admin/payments":
				return {
					pageTitle: "Payments",
					pageDescription: "Money, Money, Money",
				};
			case "/admin/inbox":
				return { pageTitle: "Inbox", pageDescription: "Just Messages" };
			case "/admin/settings":
				return { pageTitle: "Settings", pageDescription: "Just What you see" };
			// Add more cases for other routes/pages
			default:
				return {
					pageTitle: "Default Page",
					pageDescription: "Default description",
				};
		}
	};

	// Get dynamic values for the current route
	const { pageTitle, pageDescription } = getPageContent();

	return (
		<>
			<TopNav pageTitle={pageTitle} pageDescription={pageDescription} />
			<div className="app">
				<SideNav />
				<div className="content">
					<div className="contentout">
						{isLoggedin ? <Outlet /> : <RedirectToAdminLogin />}
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
