import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditDriver = () => {
	const [driver, setDriver] = useState(null);
	const { id } = useParams(); // Retrieve driver email from route params

	const [editableField, setEditableField] = useState(null);
	const [editedValues, setEditedValues] = useState({
		firstName: "",
		lastName: "",
		tel: "",
	});

	useEffect(() => {
		const fetchDriver = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setDriver(response.data.driver);

				// Set edited values initially
				setEditedValues({
					firstName: response.data.driver.firstName,
					lastName: response.data.driver.lastName,
					tel: response.data.driver.tel,
				});
			} catch (error) {
				toast.error("Error fetching driver");
			}
		};

		if (id) {
			fetchDriver();
		}
	}, [id]);

	const handleEditField = (field) => {
		setEditableField(field);
	};

	const handleSaveField = async () => {
		try {
			const token = localStorage.getItem("token");

			const updatedData = {
				firstName: editedValues.firstName,
				lastName: editedValues.lastName,
				tel: editedValues.tel,
			};

			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				updatedData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				toast.success("Driver profile updated successfully!");
				setEditableField(null); // Reset editableField after saving
				// Update the local state with edited values
				setDriver((prevDriver) => ({
					...prevDriver,
					firstName: editedValues.firstName,
					lastName: editedValues.lastName,
					tel: editedValues.tel,
				}));
			} else {
				toast.error(
					`Failed to update driver profile. Status: ${response.status}`
				);
			}
		} catch (error) {
			toast.error("Error updating driver profile");
		}
	};

	const handleInputChange = (e, field) => {
		// Update the edited values as the user types
		setEditedValues((prevValues) => ({
			...prevValues,
			[field]: e.target.value,
		}));
	};

	if (!driver) {
		return <div>Loading...</div>;
	}

	return (
		<div className="datapreview">
			<h2>{`Edit ${driver.firstName} ${driver.lastName}'s Details`}</h2>
			<p>
				<span className="info">
					Click on data to edit, Email and UserID are not editable
				</span>
			</p>
			<p>Driver ID: {driver._id}</p>
			<p>Email: {driver.email}</p>
			<p>
				<strong>First Name:</strong>{" "}
				{editableField === "firstName" ? (
					<input
						type="text"
						value={editedValues.firstName}
						onChange={(e) => handleInputChange(e, "firstName")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "firstName" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("firstName")}
					>
						{driver.firstName}
					</span>
				)}
			</p>
			<p>
				<strong>Last Name:</strong>{" "}
				{editableField === "lastName" ? (
					<input
						type="text"
						value={editedValues.lastName}
						onChange={(e) => handleInputChange(e, "lastName")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "lastName" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("lastName")}
					>
						{driver.lastName}
					</span>
				)}
			</p>
			<p>
				<strong>Telephone:</strong>{" "}
				{editableField === "tel" ? (
					<input
						type="text"
						value={editedValues.tel}
						onChange={(e) => handleInputChange(e, "tel")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "tel" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("tel")}
					>
						{driver.tel}
					</span>
				)}
			</p>
			{editableField && <button onClick={handleSaveField}>Save</button>}
		</div>
	);
};

export default EditDriver;
