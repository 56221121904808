import React, {useEffect} from 'react'

function CookiePolicyDetails() {

  useEffect(()=>{
  document.title = 'Rive | Our Cookie Policy!';
}, [])
  return (
    <React.Fragment>
      <section>
        <div className="container contact-details">
          <h1>
            Cookie Policy
          </h1>

          <h2>
            Last updated: 1st January 2024
          </h2>
          <br />
          
          <ol>
            <li>
              <h2>Introduction</h2>
              <p>This Cookie Policy explains how Rive ("we," "us," or "our") uses cookies and similar tracking technologies when you access our website or use our services. By using our website or services, you consent to the use of cookies in accordance with this policy.</p>
            </li>
            <li>
              <h2>What are Cookies?</h2>
              <p>Cookies are small text files that are placed on your device when you visit a website or use an application. They are commonly used to make websites and applications work more efficiently and to provide information to the website or application owners.</p>
            </li>
            <li>
              <h2>Types of Cookies We Use</h2>
              <ul>
                <li><strong>Website forms:</strong> These cookies are necessary for the website to function properly. They enable core functionality such as security and accessibility.</li>
                <li><strong>Performance Cookies:</strong> These cookies collect information about how visitors use our website, such as which pages are visited most frequently. This data is used to improve our website's performance.</li>
                <li><strong>Functionality Cookies:</strong> These cookies allow the website to remember choices you make, such as language preferences and custom settings.</li>
                <li><strong>Advertising Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</li>
              </ul>
            </li>
            <li>
              <h2>How We Use Cookies</h2>
              <p>We use cookies for the following purposes:</p>
              <ul>
                <li>To provide and maintain our website and services.</li>
                <li>To understand how you use our website and services.</li>
                <li>To improve your user experience by personalizing content and features.</li>
                <li>To provide targeted advertising.</li>
              </ul>
            </li>
            <li>
              <h2>Managing Cookies</h2>
              <p>You can control cookies through your browser settings. Most web browsers allow you to delete or block cookies. Please note that if you choose to disable cookies, some parts of our website or services may not function properly.</p>
            </li>
            <li>
              <h2>Third-Party Cookies</h2>
              <p>Our website and services may include content and features provided by third parties. These third parties may set their own cookies and collect information about your online activities over time and across different websites.</p>
            </li>
            <li>
              <h2>Updates to Cookie Policy</h2>
              <p>We may update this Cookie Policy from time to time to reflect changes in our use of cookies or for other operational, legal, or regulatory reasons. Any updates will be posted here.</p>
            </li>
          </ol>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CookiePolicyDetails