import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BASE_URL = `https://api.rive.ng/api/v1`;

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.post(
				`${BASE_URL}/auth/driver/forgot-password`,
				{ email }
			);
			toast.success(data.msg);
			navigate(`/forgot-verify?email=${encodeURIComponent(email)}`);
		} catch (error) {
			toast.error("Something went wrong");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="topDown">
			<Navbar />
			<div className="newLayout">
				<h1>Forgot Password?</h1>
				<p style={{ marginTop: "4px" }}>
					Enter Email address to reset your password
				</p>
				<input
					type="email"
					name="email"
					id="email"
					placeholder="example@user.com"
					className="longPassword"
					style={{ marginTop: "16px" }}
					value={email}
					onChange={(e) => {
						setEmail(e.currentTarget.value);
					}}
				/>
				<br />
				<button
					style={{ marginTop: "24px" }}
					disabled={isLoading}
					onClick={handleSubmit}
				>
					{isLoading ? `Resetting Password` : `Reset Password`}
				</button>
			</div>
			<Footer />
		</div>
	);
};

export default ForgotPassword;
