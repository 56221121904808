import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditUser = () => {
	const [user, setUser] = useState(null);
	const { email } = useParams(); // Retrieve user email from route params

	const [editableField, setEditableField] = useState(null);
	const [editedValues, setEditedValues] = useState({
		firstName: "",
		lastName: "",
		tel: "",
	});

	useEffect(() => {
		const fetchUser = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/user/${email}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setUser(response.data.user);

				// Set edited values initially
				setEditedValues({
					firstName: response.data.user.firstName,
					lastName: response.data.user.lastName,
					tel: response.data.user.tel,
				});
			} catch (error) {
				toast.error("Error fetching user");
			}
		};

		if (email) {
			fetchUser();
		}
	}, [email]);

	const handleEditField = (field) => {
		setEditableField(field);
	};

	const handleSaveField = async () => {
		try {
			const token = localStorage.getItem("token");

			const updatedData = {
				firstName: editedValues.firstName,
				lastName: editedValues.lastName,
				tel: editedValues.tel,
			};

			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/update-user/${email}`,
				updatedData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				toast.success("User profile updated successfully!");
				setEditableField(null); // Reset editableField after saving
				// Update the local state with edited valuesto
				setUser((prevUser) => ({
					...prevUser,
					firstName: editedValues.firstName,
					lastName: editedValues.lastName,
					tel: editedValues.tel,
				}));
			} else {
				toast.error(
					`Failed to update user profile. Status: ${response.status}`
				);
			}
		} catch (error) {
			toast.error("Error updating user profile");
		}
	};

	const handleInputChange = (e, field) => {
		// Update the edited values as the user types
		setEditedValues((prevValues) => ({
			...prevValues,
			[field]: e.target.value,
		}));
	};

	if (!user) {
		return <div>Loading...</div>;
	}

	return (
		<div className="datapreview">
			<h2>{`Edit ${user.firstName} ${user.lastName}'s Details`}</h2>
			<p>
				<span className="info">
					Click on data to edit, Email and UserID are not editable
				</span>
			</p>
			<p>User ID: {user._id}</p>
			<p>Email: {user.email}</p>
			<p>
				<strong>First Name:</strong>{" "}
				{editableField === "firstName" ? (
					<input
						type="text"
						value={editedValues.firstName}
						onChange={(e) => handleInputChange(e, "firstName")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "firstName" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("firstName")}
					>
						{user.firstName}
					</span>
				)}
			</p>
			<p>
				<strong>Last Name:</strong>{" "}
				{editableField === "lastName" ? (
					<input
						type="text"
						value={editedValues.lastName}
						onChange={(e) => handleInputChange(e, "lastName")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "lastName" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("lastName")}
					>
						{user.lastName}
					</span>
				)}
			</p>
			<p>
				<strong>Telephone:</strong>{" "}
				{editableField === "tel" ? (
					<input
						type="text"
						value={editedValues.tel}
						onChange={(e) => handleInputChange(e, "tel")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "tel" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("tel")}
					>
						{user.tel}
					</span>
				)}
			</p>
			{editableField && <button onClick={handleSaveField}>Save</button>}
		</div>
	);
};

export default EditUser;
