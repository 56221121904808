import React, {useEffect} from 'react'


function PrivacyDetails() {
  useEffect(()=>{
  document.title = 'Rive | Our Privacy Policy!';
}, [])

  return (
    <React.Fragment>
      <section>
        <div className="container contact-details">
          <h1>
            Privacy Policy
          </h1>

          <h2>
            Last updated: 1st January 2024
          </h2>
          <br />
          
          <ol>
            <li>
              <h2>Introduction</h2>
              <p>Welcome to Rive! This Privacy Policy describes how Rive ("we," "us," or "our") collects, uses, and protects your personal information when you use our services. Please read this Privacy Policy carefully to understand how we handle your data. By accessing or using our services, you consent to the practices described in this policy.</p>
            </li>
            <li>
              <h2>Information We Collect</h2>
              <p>We collect the following types of personal information:</p>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Government-issued IDs</li>
              </ul>
            </li>
            <li>
              <h2>How We Collect Information</h2>
              <p>We collect personal information through:</p>
              <ul>
                <li>Website forms</li>
                <li>Cookies</li>
                <li>Directly from users</li>
                <li>Third parties</li>
              </ul>
            </li>
            <li>
              <h2>Purposes of Data Collection</h2>
              <p>We collect personal information for the following purposes:</p>
              <ul>
                <li>Creating user accounts</li>
                <li>Processing orders</li>
                <li>Personalizing user experiences</li>
                <li>Marketing</li>
                <li>Analytics</li>
                <li>Research</li>
              </ul>
            </li>
            <li>
              <h2>Data Security</h2>
              <p>We take steps to protect and secure your personal information, including encryption during transmission and storage.</p>
            </li>
            <li>
              <h2>Sharing Information</h2>
              <p>We do not share personal information with third parties.</p>
            </li>
            <li>
              <h2>User Rights</h2>
              <p>Users have the right to access, correct, or delete their personal data. Users can also opt out of data collection or request data portability.</p>
            </li>
            <li>
              <h2>Cookies and Tracking Technologies</h2>
              <p>We use cookies and tracking technologies for various purposes, including tracking user behavior and preferences and for advertising and analytics.</p>
            </li>
            <li>
              <h2>Regulatory Compliance</h2>
              <p>We may not currently be in compliance with specific data protection regulations. Please contact us for more information regarding the handling of your data.</p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>For privacy-related inquiries, you can contact us at <a href="mailto:privacy@rive.com.">privacy@rive.com.</a></p> 
            </li>
            <li>
              <h2>Updates to Privacy Policy</h2>
              <p>We do not currently update our privacy policy. Any future updates will be posted here.</p> 
            </li>
            <li>
              <h2>Children's Information</h2>
              <p>Children are not allowed to use our services, and we do not knowingly collect data from children.</p> 
            </li>
            <li>
              <h2>Data Retention and Deletion</h2>
              <p>User data is deleted immediately.</p> 
            </li>
            <li>
              <h2> Analytics and Advertising Services</h2>
              <p>We use third-party analytics and advertising services. Users may have opt-out options for these services.</p> 
            </li>
            <li>
              <h2> Data Breach</h2>
              <p>We do not currently have a specific data breach response plan.</p> 
            </li>
          </ol>
        </div>
      </section>
    </React.Fragment>
  )
}

export default PrivacyDetails