// import left from '../Images/left.svg'
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const UserImage = () => {
	const [loading, setLoading] = useState(false);
	const [profilePicture, setProfilePicture] = useState(null);
	const navigate = useNavigate();

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setProfilePicture(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!profilePicture) {
			toast.error("Please upload a profile picture", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setLoading(false);
			return;
		}

		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");

		const formData = new FormData();
		formData.append("passport", profilePicture);

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				formData,
				config
			);

			toast.success("Profile picture uploaded successfully", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			navigate("/user-verify-docs");
		} catch (error) {
			// console.error("Error during profile picture upload:", error);
			toast.error("An error occurred during profile picture upload", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="container reg">
			{/* <Link to="/user-verify-contact" className='outline'>
        <button type="button" className='outline'>
          <img src={left} alt="" />
        </button>
      </Link> */}
			<div className="heads">
				<h1>Let’s see your Face</h1>
				<p>Smile and upload your picture</p>
			</div>

			<div>
				<form className="regwrapper" onSubmit={handleSubmit}>
					<div className="formregwrap names">
						<div className="names">
							<label htmlFor="ProfilePics">Upload a Photo:</label>
							<input
								type="file"
								name="ProfilePics"
								id="ProfilePics"
								accept="image/*"
								onChange={handleFileChange}
							/>
						</div>
					</div>

					<button type="submit" className="utility">
						{loading ? "Uploading..." : "Continue"}
					</button>
				</form>
			</div>
			<ToastContainer />
		</section>
	);
};

export default UserImage;
