import React from 'react'
import qrcode from '../Images/qrcode.png'

function DownloadContents() {
  return (
    <React.Fragment>
      <section className='ForDownload'>
        <div className="container">
          <p>For Users</p>
        </div>
        <div className="container downloaded">
          <div className='container'>
            <div className='down-wrapper'>
            <div>
              <h1>
                Download the Rive App
              </h1>
              <p>For iOS</p>
            </div>
            <img src={qrcode} alt="qrcode" />
            </div>
          </div>
          <div className='container'>
            <div className='down-wrapper'>
            <div>
              <h1>
                Download the Rive App
              </h1>
              <p>For iOS</p>
            </div>
            <img src={qrcode} alt="qrcode" />
            </div>
          </div>
        </div>
      </section>
       <section className='ForDownload'>
        <div className="container">
          <p>For Drivers</p>
        </div>
        <div className="container downloaded">
          <div className='container'>
            <div className='down-wrapper'>
            <div>
              <h1>
                Download the Rive App
              </h1>
              <p>For iOS</p>
            </div>
            <img src={qrcode} alt="qrcode" />
            </div>
          </div>
          <div className='container'>
            <div className='down-wrapper'>
            <div>
              <h1>
                Download the Rive App
              </h1>
              <p>For iOS</p>
            </div>
            <img src={qrcode} alt="qrcode" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default DownloadContents