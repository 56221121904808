import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ViewUser = () => {
	const [user, setUser] = useState(null);
	const { email } = useParams(); // Retrieve user email from route params

	useEffect(() => {
		const fetchUser = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/user/${email}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setUser(response.data.user);
			} catch (error) {
				toast.error("Error fetching user:", error);
			}
		};

		if (email) {
			fetchUser();
		}
	}, [email]);

	if (!user) {
		return <div>Loading...</div>;
	}

	return (
		<div className="datapreview">
			<h2>{`${user.firstName} ${user.lastName}'s Details`}</h2>
			<p>User ID: {user._id}</p>
			<p>Email: {user.email}</p>
			<p>First Name: {user.firstName}</p>
			<p>Last Name: {user.lastName}</p>
			<p>Telephone: {user.tel}</p>
			{/* Add any other information you want to display */}
		</div>
	);
};

export default ViewUser;
