import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DocDocumets from "./DocDocumets"; // Retaining the typo if necessary
import { toast } from "react-toastify";

const ViewDriver = () => {
	const [driver, setDriver] = useState(null);
	const { id } = useParams(); // Retrieve driver ID from route params

	useEffect(() => {
		const fetchDriver = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setDriver(response.data.driver);
			} catch (error) {
				toast.error("Error fetching driver:", error);
			}
		};

		if (id) {
			fetchDriver();
		}
	}, [id]);

	const handleApproveDriver = async () => {
		// Send PATCH request to update isVerified to true
		const token = localStorage.getItem("token");

		try {
			await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				{ isVerified: true },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// Update local state to reflect the change
			toast.success("Driver Approved!");
			setDriver((prevDriver) => ({ ...prevDriver, isVerified: true }));
		} catch (error) {
			toast.error("Error approving driver:", error);
		}
	};

	const handleWithdrawApproval = async () => {
		// Send PATCH request to update isVerified to false
		const token = localStorage.getItem("token");

		try {
			await axios.patch(
				`https://rive-backend.vercel.app/api/v1/auth/driver/${id}`,
				{ isVerified: false },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// Update local state to reflect the change
			toast.warning("Driver Approval Withdrawn");
			setDriver((prevDriver) => ({ ...prevDriver, isVerified: false }));
		} catch (error) {
			toast.error("Error withdrawing approval:", error);
		}
	};

	if (!driver) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div className="datapreview">
				<h2>{`${driver.firstName} ${driver.lastName}'s Details`}</h2>
				<p>Driver ID: {driver._id}</p>
				<p>Email: {driver.email}</p>
				<p>First Name: {driver.firstName}</p>
				<p>Last Name: {driver.lastName}</p>
				<p>Telephone: {driver.tel}</p>
				<p>
					Verification Status: {driver.isVerified ? "Verified" : "Not Verified"}
				</p>
				<p>Role: {driver.role}</p>
			</div>
			<br />
			<DocDocumets />

			<br />

			{/* Place the buttons below DocDocuments */}
			<div>
				<button
					onClick={
						driver.isVerified ? handleWithdrawApproval : handleApproveDriver
					}
					className={driver.isVerified ? "RedadminSearch" : "adminSearch"}
				>
					{driver.isVerified ? "Withdraw Approval" : "Approve Driver"}
				</button>
			</div>
		</>
	);
};

export default ViewDriver;
