import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useAuthentication } from "../AuthContext";
import CloseIcon from "../CloseIcon";

function Hero() {
	const { setToken } = useAuthentication();
	const modalRef = useRef(null);
	const [credentials, setCredentials] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		tel: "",
		typeOfAutomobile: "",
		licencePlateNumber: "",
		automobileColor: "",
		automobileName: "",
	});
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const handleSignUp = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (credentials.password !== credentials.confirmPassword) {
			// Passwords do not match
			toast.error("Passwords do not match", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setIsLoading(false);
			return;
		}

		try {
			const response = await axios.post(
				" https://api.rive.ng/api/v1/auth/driver/register",
				credentials
			);

			// console.log(response.data);
			const token = response.data.token;
			const id = response.data.driver._id;
			localStorage.setItem("token", token);
			setToken(token);
			localStorage.setItem("id", id);
			// Sign-up successful
			toast.success("Sign-up successful", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			// Pass the email to the UserVerifyContent component when navigating
			navigate(`/user-verify?email=${encodeURIComponent(credentials.email)}`);
		} catch (error) {
			console.error("Error during sign-up:", error);
			toast.error(error.response.data.msg, {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setIsLoading(false); // Set loading state to false after request completes
		}
	};

	return (
		<>
			<section className="hero">
				<div className="container contain">
					<div className="hero-text">
						<h1>Drive, Rive, & Make Money Anywhere.</h1>
						<p>
							With our innovative platform, you have the power to take control
							of your journey...
						</p>
					</div>
					<div className="form-wrapper">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								modalRef.current.showModal();
							}}
						>
							<label htmlFor="email">Email address</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="example@user.com"
								autoComplete="on"
								value={credentials.email}
								onChange={(e) =>
									setCredentials({ ...credentials, email: e.target.value })
								}
							/>
							<label htmlFor="password">Password</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="Enter password"
								value={credentials.password}
								onChange={(e) =>
									setCredentials({ ...credentials, password: e.target.value })
								}
							/>
							<label htmlFor="confirmPassword">Confirm Password</label>
							<input
								type="password"
								name="confirmPassword"
								id="confirmPassword"
								placeholder="Confirm password"
								value={credentials.confirmPassword}
								onChange={(e) =>
									setCredentials({
										...credentials,
										confirmPassword: e.target.value,
									})
								}
							/>

							<button type="submit">Sign Up as a driver</button>
							<p className="center">or</p>
							<Link to="/login">
								<button className="secondary">Login as a Driver</button>
							</Link>
						</form>
						<dialog ref={modalRef} className="dialog">
							<div
								className="abs"
								onClick={() => {
									modalRef.current.close();
								}}
							>
								<CloseIcon />
							</div>
							<form onSubmit={handleSignUp}>
								<label htmlFor="first name">First Name</label>
								<input
									type="text"
									name="first name"
									id="first name"
									placeholder="John"
									value={credentials.firstName}
									onChange={(e) =>
										setCredentials({
											...credentials,
											firstName: e.target.value,
										})
									}
								/>
								<label htmlFor="Last name">Last Name</label>
								<input
									type="text"
									name="Last name"
									id="Last name"
									placeholder="Doe"
									value={credentials.lastName}
									onChange={(e) =>
										setCredentials({
											...credentials,
											lastName: e.target.value,
										})
									}
								/>
								<label htmlFor="Phone number">Phone number</label>
								<input
									type="tel"
									name="Phone number"
									id="Phone number"
									placeholder="+234"
									value={credentials.tel}
									onChange={(e) =>
										setCredentials({
											...credentials,
											tel: e.target.value,
										})
									}
								/>
								<label htmlFor="Vehicle Name">Vehicle Name</label>
								<input
									type="text"
									name="Vehicle Name"
									id="Vehicle Name"
									placeholder="Toyota Corolla"
									value={credentials.automobileName}
									onChange={(e) =>
										setCredentials({
											...credentials,
											automobileName: e.target.value,
										})
									}
								/>
								<label htmlFor="Phone number">Vehicle Type</label>
								<select
									value={credentials.typeOfAutomobile}
									onChange={(e) =>
										setCredentials({
											...credentials,
											typeOfAutomobile: e.target.value,
										})
									}
								>
									<option value="" disabled>
										Select a vehicle type
									</option>
									<option value="car">Car</option>
									<option value="bike">Bike</option>
								</select>
								<label htmlFor="vehicle number">Vehicle PLate Number</label>
								<input
									type="text"
									name="vehicle number"
									id="vehicle number"
									placeholder="ABC-1234"
									value={credentials.licencePlateNumber}
									onChange={(e) =>
										setCredentials({
											...credentials,
											licencePlateNumber: e.target.value,
										})
									}
								/>
								<label htmlFor="vehicle color">Vehicle Colour</label>
								<input
									type="text"
									name="vehicle color"
									id="vehicle color"
									placeholder="red"
									value={credentials.automobileColor}
									onChange={(e) =>
										setCredentials({
											...credentials,
											automobileColor: e.target.value,
										})
									}
								/>
								<button type="submit">
									{isLoading ? "Completing sign up..." : "Complete Signup"}
								</button>
							</form>
						</dialog>
					</div>
				</div>
				<ToastContainer />
			</section>
		</>
	);
}

export default Hero;
