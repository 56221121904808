import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import './DriverSettings.css'; // Import your CSS file

const DriverSettings = () => {
	const [driverData, setDriverData] = useState(null);
	const [editableField, setEditableField] = useState(null);
	const [editedValues, setEditedValues] = useState({
		firstname: "",
		lastname: "",
		tel: "",
	});

	const fetchDriverData = async () => {
		try {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id");

			const response = await axios.get(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const data = response.data.driver;

			setDriverData({
				email: data.email,
				firstname: data.firstName,
				lastname: data.lastName,
				tel: data.tel,
				role: data.role,
			});

			// Set edited values initially
			setEditedValues({
				firstname: data.firstName,
				lastname: data.lastName,
				tel: data.tel,
			});
		} catch (error) {
			// console.log('Error fetching data from the API', error);
		}
	};

	useEffect(() => {
		fetchDriverData();
	}, []);

	const handleEditField = (field) => {
		setEditableField(field);
	};

	const handleSaveField = async () => {
		try {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id");

			const updatedData = {
				firstName: editedValues.firstname,
				lastName: editedValues.lastname,
				tel: editedValues.tel,
			};

			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				updatedData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				toast.success("Profile updated successfully!");
				setEditableField(null); // Reset editableField after saving
				fetchDriverData(); // Refetch data after saving
			} else {
				toast.error(`Failed to update profile`);
			}
		} catch (error) {
			// console.error('Error updating profile:', error);
		}
	};

	const handleInputChange = (e, field) => {
		// Update the edited values as the user types
		setEditedValues((prevValues) => ({
			...prevValues,
			[field]: e.target.value,
		}));
	};

	if (!driverData) {
		return <div>Loading...</div>;
	}

	return (
		<div className="datapreview">
			<p>
				<span className="info-blue">
					Click on data to edit, Email and UserID are not editable
				</span>
			</p>
			<p>
				<strong>Email:</strong> {driverData.email}
			</p>
			<p>
				<strong>First Name:</strong>{" "}
				{editableField === "firstname" ? (
					<input
						type="text"
						value={editedValues.firstname}
						onChange={(e) => handleInputChange(e, "firstname")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "firstname" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("firstname")}
					>
						{driverData.firstname}
					</span>
				)}
			</p>
			<p>
				<strong>Last Name:</strong>{" "}
				{editableField === "lastname" ? (
					<input
						type="text"
						value={editedValues.lastname}
						onChange={(e) => handleInputChange(e, "lastname")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "lastname" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("lastname")}
					>
						{driverData.lastname}
					</span>
				)}
			</p>
			<p>
				<strong>Telephone:</strong>{" "}
				{editableField === "tel" ? (
					<input
						type="text"
						value={editedValues.tel}
						onChange={(e) => handleInputChange(e, "tel")}
					/>
				) : (
					<span
						className={`editable-field ${
							editableField === "tel" ? "editable-field-active" : ""
						}`}
						onClick={() => handleEditField("tel")}
					>
						{driverData.tel}
					</span>
				)}
			</p>
			<p>
				<strong>Role:</strong> {driverData.role}
			</p>
			{editableField && <button onClick={handleSaveField}>Save</button>}
		</div>
	);
};

export default DriverSettings;
