import React from 'react'
import ErrorNav from './ErrorNav'
import ProgressBar from './ProgressBar'
import UserImage from './UserImage'

const UserImageDetails = () => {
  return (
   <>
    <ErrorNav/>
    <ProgressBar/>
    <UserImage/>
   </>
  )
}

export default UserImageDetails