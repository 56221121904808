import React from 'react'
import ErrorNav from './ErrorNav'
import ProgressBar from './ProgressBar'
import UserDocumentDetails from './UserDocumentDetails'

function UploadDocument() {
  return (
    <>
      <ErrorNav/>
      <ProgressBar/>
      <UserDocumentDetails/>
      
        
      
    </>
  )
}

export default UploadDocument