import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import PrivacyDetails from './PrivacyDetails'
function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Navbar/>
      <PrivacyDetails/>
      <Footer/>
    </React.Fragment>
  )
}

export default PrivacyPolicy