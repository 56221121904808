import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const DocDocuments = () => {
	const [driver, setDriver] = useState(null);
	const { id } = useParams(); // Get the id from the route params

	useEffect(() => {
		const fetchDriverData = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setDriver(response.data.driver);
			} catch (error) {
				toast.error("Error fetching driver data:");
			}
		};

		if (id) {
			fetchDriverData();
		}
	}, [id]);

	return (
		<div>
			<h2 className="docspace">Documents</h2>
			<table>
				<thead>
					<tr>
						<th>File</th>
						<th>Description</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{driver && (
						<>
							{/* NIN */}
							<tr>
								<td>NIN</td>
								<td>
									{driver.credentials && driver.credentials?.nin?.imageUrl ? (
										<a
											href={driver.credentials?.nin?.imageUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											National Identifcation Number Document
										</a>
									) : (
										"User has not uploaded NIN"
									)}
								</td>
								<td>{driver.credentials && driver.credentials?.nin?.action}</td>
							</tr>

							{/* Driver's License */}
							<tr>
								<td>Driver's License</td>
								<td>
									{driver.credentials &&
									driver.credentials?.driverLicense?.imageUrl ? (
										<a
											href={driver.credentials?.driverLicense?.imageUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											Driver's License Doument
										</a>
									) : (
										"User hasn't uploaded Driver's License Document"
									)}
								</td>
								<td>
									{driver.credentials &&
										driver.credentials?.driverLicense?.action}
								</td>
							</tr>
						</>
					)}
					{!driver && (
						<>
							{/* NIN */}
							<tr>
								<td>NIN</td>
								<td>User has not uploaded NIN</td>
								<td></td>
							</tr>

							{/* Driver's License */}
							<tr>
								<td>Driver's License</td>
								<td>User hasn't uploaded Driver's License Document</td>
								<td></td>
							</tr>
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default DocDocuments;
