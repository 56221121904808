import React, {useEffect} from 'react'

function ContactDetails() {

  useEffect(()=>{
  document.title = 'Rive | We\'d like to hear from you' ;
}, [])
  return (
    <React.Fragment>
      <section>
        <div className="container contact-details">
          <h1>
            Contact
          </h1>
          <p>
            We'd love to hear from you and answer any questions you may have. Whether you want to provide feedback, inquire about our services, or simply say hello, here's how you can reach us:
          </p>

          <br /><br />
          <h2>
            Customer Support
          </h2>
          <p>
            If you need assistance or have any inquiries, our dedicated customer support team is here to help. Reach out to us via email at <a href="mailto:support@rive.ng">support@rive.ng</a>  or call us at <a href="tel:+234-XXX-XXX-XXXX">+234-XXX-XXX-XXXX</a>. We're available to assist you 24/7.
          </p>
          <br />
          <h2>
            Business Inquiries:
          </h2>
          <p>
            For business partnerships, collaborations, or other opportunities, please contact us at <a href="mailto:support@rive.ng">business@rive.ng</a>. We're open to exploring new ventures and ideas.
          </p>
          <br />
          <h2>
            General Inquiries:
          </h2>
          <p>
            If you have general questions or feedback, you can send an email to <a href="mailto:support@rive.ng">mail@rive.ng</a>, and we'll get back to you as soon as possible.
          </p>
          <br /><br/>
          <p>
            Your input and support are essential to our success. Thank you for choosing Rive as your logistics partner in Abeokuta, and we look forward to serving you!
          </p>
          <br /><br /><br /> <br /><br />
        </div>
      </section>
    </React.Fragment>
  )
}

export default ContactDetails