import React from 'react'


const DashboardContent = () => {


  return (
   <div>Hello</div>
  
  )
}

export default DashboardContent