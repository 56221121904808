import React, { useState, useEffect } from "react";
import axios from "axios";

const DriverDocs = () => {
	const [driver, setDriver] = useState(null);

	useEffect(() => {
		const fetchDriverData = async () => {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/auth/driver/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				// console.log(response.data.driver);

				setDriver(response.data.driver);
			} catch (error) {
				// console.error('Error fetching driver data:', error);
			}
		};

		const id = localStorage.getItem("id");
		if (id !== null && id !== undefined) {
			fetchDriverData();
		}
	}, []);

	return (
		<div>
			<h2 className="docspace">Documents</h2>
			<table>
				<thead>
					<tr>
						<th>File</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{driver && (
						<>
							{/* NIN */}
							<tr>
								<td>NIN</td>
								<td>
									{driver.credentials && driver.credentials?.nin?.imageUrl ? (
										<a
											href={driver.credentials?.nin?.imageUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											National Identification Number Document
										</a>
									) : (
										"You have not uploaded NIN"
									)}
								</td>
							</tr>

							{/* Driver's License */}
							<tr>
								<td>Driver's License</td>
								<td>
									{driver.credentials &&
									driver.credentials?.driverLicense?.imageUrl ? (
										<a
											href={driver.credentials?.driverLicense?.imageUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											Driver's License Document
										</a>
									) : (
										"You have not uploaded Driver's License Document"
									)}
								</td>
							</tr>
						</>
					)}
					{!driver && (
						<>
							{/* NIN */}
							<tr>
								<td>NIN</td>
								<td>You have not uploaded NIN</td>
							</tr>

							{/* Driver's License */}
							<tr>
								<td>Driver's License</td>
								<td>You have not uploaded Driver's License Document</td>
							</tr>
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default DriverDocs;
