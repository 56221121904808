import React from 'react'
import { Link } from 'react-router-dom'

function ErrorNav() {
  return (
    <nav>
    <div className="container navigation">  
    <Link to='/' className='logo'> Rive</Link>
      
    </div>
  </nav>
  )
}

export default ErrorNav