import React from 'react';
import complete from '../Images/complete.png';
import delivery from '../Images/delivery.png';
import payment from '../Images/payment.png';

function Features() {
  return (
    <section className="features">
      <div className="container feature" id="features">
        <div className="text"><h1>Flexible Payment Choices</h1>
          <p>Choose from various payment methods for your convenience. Pay the way that suits you best.</p></div>
          <img src={payment} alt="Payment Choices" loading="lazy"/>
      </div>
      <div className="container feature">
        <div className="text">
          <h1>On-Demand Delivery Services</h1>
          <p>Need something delivered? Rive has you covered. Get your packages, groceries, and more delivered right to your doorstep quickly and affordably.</p>
        </div>
          <img src={delivery} alt="Payment Choices" loading="lazy"/>
      </div>
      <div className="container feature">
        <div className="text">
          <h1>Quality Service, Every Time</h1>
          <p>Our professional drivers and delivery partners are dedicated to providing top-notch service. Your satisfaction is our priority.</p>
        </div>
          <img src ={complete}  alt="Payment Choices" loading="lazy"/>
      </div>
    </section>
  );
}

export default Features