import React from 'react';
import Navbar from './Components/Navbar'
import Hero from './Components/hero'
import Features from './Components/Features'
import Footer from './Components/Footer'


const App = ()=> {
  return (
    <React.Fragment>
     <Navbar/>
     <Hero/>
     <Features/>
     <Footer/>
   </React.Fragment>
);
    
}

export default App
