import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsisV,
	faEye,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const LoadingSpinner = () => (
	<div className="loading-spinner">
		<FontAwesomeIcon icon={faSpinner} spin />
	</div>
);

// TripOptionsMenu component
const TripOptionsMenu = ({ trip, onClose, onAction }) => {
	const handleMenuItemClick = (action, tripId) => {
		onAction(action, tripId);
		onClose();
	};

	return (
		<div className="popupMenu">
			<Link
				to={`/admin/view-trip/${trip._id}`}
				onClick={() => handleMenuItemClick("View", trip._id)}
			>
				<FontAwesomeIcon icon={faEye} /> View More Trip Details
			</Link>
		</div>
	);
};

// Trips component
const Trips = () => {
	const [loading, setLoading] = useState(true);
	const [trips, setTrips] = useState([]);
	const [sortOrder, setSortOrder] = useState({
		column: "firstname",
		direction: "asc",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedTrip, setSelectedTrip] = useState(null);
	const [openDropdowns, setOpenDropdowns] = useState({});
	const [selectedTripId, setSelectedTripId] = useState(null);
	const itemsPerPage = 10;

	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem("token");
			try {
				const response = await axios.get("https://api.rive.ng/api/v1/rive", {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				setTrips(response.data.rives);
				setLoading(false);
			} catch (error) {
				toast.error("Error fetching trips");
			}
		};

		fetchData();
	}, []);

	const columnValue = (trip, column) => {
		if (!trip || !column) {
			return "";
		}

		const columnNameMapping = {
			pickuplocation: "origin.desc",
			dropofflocation: "destination.desc",
			date: "createdAt",
		};

		const apiColumnName = columnNameMapping[column] || column;
		const nestedProperties = apiColumnName.split(".");
		const nestedValue = nestedProperties.reduce(
			(obj, prop) => (obj && obj[prop]) || "",
			trip
		);

		return String(nestedValue).toLowerCase();
	};

	const handleSort = (column) => {
		setSortOrder((prevSortOrder) => ({
			column,
			direction:
				prevSortOrder.column === column && prevSortOrder.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const handleActionClick = async (trip) => {
		if (trip && trip._id) {
			const tripId = trip._id;

			if (openDropdowns[tripId]) {
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[tripId]: false,
				}));
			} else {
				if (Object.keys(openDropdowns).length > 0) {
					setOpenDropdowns({});
				}

				setSelectedTrip(trip);
				setSelectedTripId(tripId);
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[tripId]: true,
				}));
			}
		} else {
			toast.error("Invalid trip object");
		}
	};

	const handleCloseDropdown = () => {
		setOpenDropdowns({});
		setSelectedTrip(null);
		setSelectedTripId(null);
	};

	const handleMenuItemClick = async (action, tripId) => {
		handleCloseDropdown();

		// Handle actions accordingly (e.g., call an API to perform the action)
		// You can implement similar logic as in the Drivers component
	};

	const renderSortIcon = (column) => {
		const isActive = sortOrder.column === column;
		const arrow = sortOrder.direction === "asc" ? "▲" : "▼";

		return (
			<span className="adminSearchIcon" onClick={() => handleSort(column)}>
				{isActive ? arrow : ""}
			</span>
		);
	};

	const indexOfLastTrip = currentPage * itemsPerPage;
	const indexOfFirstTrip = indexOfLastTrip - itemsPerPage;
	const sortedTrips = [...trips].sort((a, b) => {
		const columnA = columnValue(a, sortOrder.column).toLowerCase();
		const columnB = columnValue(b, sortOrder.column).toLowerCase();

		if (columnA < columnB) {
			return sortOrder.direction === "asc" ? -1 : 1;
		}
		if (columnA > columnB) {
			return sortOrder.direction === "asc" ? 1 : -1;
		}
		return 0;
	});
	const currentTrips = sortedTrips.slice(indexOfFirstTrip, indexOfLastTrip);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div>
			{loading ? (
				<LoadingSpinner />
			) : (
				<table>
					<thead>
						<tr>
							<th
								className="adminclick"
								onClick={() => handleSort("firstname")}
							>
								Firstname {renderSortIcon("firstname")}
							</th>
							<th className="adminclick" onClick={() => handleSort("email")}>
								Email {renderSortIcon("email")}
							</th>
							<th
								className="adminclick"
								onClick={() => handleSort("pickuplocation")}
							>
								Pickup Location {renderSortIcon("pickuplocation")}
							</th>
							<th
								className="adminclick"
								onClick={() => handleSort("dropofflocation")}
							>
								Drop Off Location {renderSortIcon("dropofflocation")}
							</th>
							<th className="adminclick" onClick={() => handleSort("date")}>
								Date {renderSortIcon("date")}
							</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{currentTrips.map((trip) => (
							<tr key={trip._id}>
								<td>{trip.user && trip.user.firstName}</td>
								<td>{trip.user && trip.user.email}</td>
								<td>{columnValue(trip, "pickuplocation")}</td>
								<td>{columnValue(trip, "dropofflocation")}</td>
								<td>{columnValue(trip, "date")}</td>
								<td>
									<div className="actionWrapper">
										<button
											className="actionButton"
											onClick={() => handleActionClick(trip)}
										>
											<FontAwesomeIcon icon={faEllipsisV} />
										</button>
										{openDropdowns[trip._id] && (
											<TripOptionsMenu
												trip={selectedTrip}
												onClose={handleCloseDropdown}
												onAction={(action) =>
													handleMenuItemClick(action, selectedTripId)
												}
											/>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			<div className="paginations">
				{Array.from({
					length: Math.ceil(sortedTrips.length / itemsPerPage),
				}).map((_, index) => (
					<button
						key={index}
						className={
							currentPage === index + 1 ? "adminSearch" : "secondarySearcher"
						}
						onClick={() => paginate(index + 1)}
					>
						{index + 1}
					</button>
				))}
			</div>
		</div>
	);
};

export default Trips;
