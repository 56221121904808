import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsisV,
	faEye,
	// faEdit,
	faSpinner,
	faTrash,
	faPlay,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const LoadingSpinner = () => (
	<div className="loading-spinner">
		<FontAwesomeIcon icon={faSpinner} spin />
	</div>
);

const UserOptionsMenu = ({
	user,
	onClose,
	onAction,
	handleDeactivateUser,
	handleActivateUser,
}) => {
	const handleMenuItemClick = async (action, userId) => {
		onClose();

		if (action === "Review") {
			// You can add specific logic for the 'Review' action here if needed
		} else if (action === "Edit") {
			// Handle Edit action
			// You can perform additional actions specific to 'Edit' here
		} else if (action === "Deactivate") {
			const confirmAction = window.confirm(
				`Are you sure you want to deactivate this user?`
			);
			if (confirmAction) {
				await handleDeactivateUser(userId);
				onAction(action);
			}
		} else if (action === "Activate") {
			const confirmAction = window.confirm(
				`Are you sure you want to activate this user?`
			);
			if (confirmAction) {
				await handleActivateUser(userId);
				onAction(action);
			}
		}
	};

	return (
		<div className="popupMenu">
			<Link
				to={`/admin/view/${user.email}`}
				onClick={() => handleMenuItemClick("Review", user.email)}
			>
				<FontAwesomeIcon icon={faEye} /> Review User
			</Link>

			{/* <Link
        to={`/admin/edit/${user.email}`}
        onClick={() => handleMenuItemClick('Edit', user.email)}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit User
      </Link> */}

			{user.isActive ? (
				<div onClick={() => handleMenuItemClick("Deactivate", user.email)}>
					<FontAwesomeIcon icon={faTrash} /> Deactivate User
				</div>
			) : (
				<div onClick={() => handleMenuItemClick("Activate", user.email)}>
					<FontAwesomeIcon icon={faPlay} /> Activate User
				</div>
			)}
		</div>
	);
};

const Users = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [sortOrder, setSortOrder] = useState({
		column: "firstname",
		direction: "asc",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedUser, setSelectedUser] = useState(null);
	const [openDropdowns, setOpenDropdowns] = useState({});
	const itemsPerPage = 10;

	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem("token");
			try {
				const response = await axios.get(
					"https://api.rive.ng/api/v1/auth/all-users",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setUsers(response.data.users);
				setLoading(false);
			} catch (error) {
				toast.error("Error fetching users");
			}
		};

		fetchData();
	}, []);

	const columnValue = (user, column) => {
		if (!user || !column) {
			return "";
		}

		const columnNameMapping = {
			firstname: "firstName",
			lastname: "lastName",
			phonenumber: "tel",
			status: "isActive",
			// Add other mappings as needed
		};

		const apiColumnName = columnNameMapping[column] || column;
		const value = user[apiColumnName];

		if (column === "status" && typeof value === "boolean") {
			return value ? "Active" : "Suspended";
		}

		return String(value).toLowerCase();
	};

	const handleSort = (column) => {
		setSortOrder((prevSortOrder) => ({
			column,
			direction:
				prevSortOrder.column === column && prevSortOrder.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const handleDeactivateUser = async (email) => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/update-user/${email}`,
				{ isActive: false },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// console.log("Activate User Backend Response:", response.data);

			if (response.status === 200) {
				toast.success("User deactivated successfully!");
				setUsers((prevUsers) =>
					prevUsers.map((user) =>
						user.email === email ? { ...user, isActive: false } : user
					)
				);
			} else {
				toast.error(`Failed to deactivate user. Status: ${response.status}`);
			}
		} catch (error) {
			toast.error("Error deactivating user");
		}
	};

	const handleActivateUser = async (email) => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/update-user/${email}`,
				{ isActive: true },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// console.log("Activate User Backend Response:", response.data);

			if (response.status === 200) {
				toast.success("User activated successfully!");
				setUsers((prevUsers) =>
					prevUsers.map((user) =>
						user.email === email ? { ...user, isActive: true } : user
					)
				);
			} else {
				toast.error(`Failed to activate user. Status: ${response.status}`);
			}
		} catch (error) {
			toast.error("Error activating user");
		}
	};

	const handleActionClick = async (user) => {
		if (user && user._id) {
			if (openDropdowns[user._id]) {
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[user._id]: false,
				}));
			} else {
				if (Object.keys(openDropdowns).length > 0) {
					setOpenDropdowns({});
				}

				setSelectedUser(user);
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[user._id]: true,
				}));
			}
		} else {
			toast.error("Invalid user object");
		}
	};

	const handleCloseDropdown = () => {
		setOpenDropdowns({});
		setSelectedUser(null);
	};

	const renderSortIcon = (column) => {
		const isActive = sortOrder.column === column;
		const arrow = sortOrder.direction === "asc" ? "▲" : "▼";

		return (
			<span className="adminSearchIcon" onClick={() => handleSort(column)}>
				{isActive ? arrow : ""}
			</span>
		);
	};

	const indexOfLastUser = currentPage * itemsPerPage;
	const indexOfFirstUser = indexOfLastUser - itemsPerPage;
	const sortedUsers = [...users].sort((a, b) => {
		const columnA = columnValue(a, sortOrder.column).toLowerCase();
		const columnB = columnValue(b, sortOrder.column).toLowerCase();

		if (columnA < columnB) {
			return sortOrder.direction === "asc" ? -1 : 1;
		}
		if (columnA > columnB) {
			return sortOrder.direction === "asc" ? 1 : -1;
		}
		return 0;
	});
	const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (!e.target.closest(".actionButton") && !e.target.closest(".overlay")) {
				handleCloseDropdown();
			}
		};

		document.addEventListener("click", handleOutsideClick);

		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	return (
		<div>
			<div className="brox">
				{loading ? (
					<LoadingSpinner />
				) : (
					<table className="controlMobile">
						<thead>
							<tr>
								<th
									className="adminclick"
									onClick={() => handleSort("firstname")}
								>
									Firstname {renderSortIcon("firstname")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("lastname")}
								>
									Lastname {renderSortIcon("lastname")}
								</th>
								<th className="adminclick" onClick={() => handleSort("email")}>
									Email {renderSortIcon("email")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("phonenumber")}
								>
									Phone Number {renderSortIcon("phonenumber")}
								</th>
								<th className="adminclick" onClick={() => handleSort("status")}>
									User Status {renderSortIcon("status")}
								</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentUsers.map((user) => (
								<tr key={user._id}>
									<td>{user.firstName}</td>
									<td>{user.lastName}</td>
									<td>{user.email}</td>
									<td>{columnValue(user, "phonenumber")}</td>
									<td>{columnValue(user, "status")}</td>
									<td>
										<button
											className={`actionButton ${
												user.isActive ? "activateButton" : "deactivateButton"
											}`}
											onClick={() => handleActionClick(user)}
										>
											<FontAwesomeIcon icon={faEllipsisV} />
										</button>
										{openDropdowns[user._id] && (
											<div className="overlay">
												<UserOptionsMenu
													user={selectedUser}
													onClose={handleCloseDropdown}
													onAction={() => handleActionClick(selectedUser)}
													handleDeactivateUser={handleDeactivateUser}
													handleActivateUser={handleActivateUser}
												/>
											</div>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			<div className="paginations">
				{Array.from({
					length: Math.ceil(sortedUsers.length / itemsPerPage),
				}).map((_, index) => (
					<button
						key={index}
						className={
							currentPage === index + 1 ? "adminSearch" : "secondarySearcher"
						}
						onClick={() => paginate(index + 1)}
					>
						{index + 1}
					</button>
				))}
			</div>
		</div>
	);
};

export default Users;
