import React from 'react'
import Navbar from './Navbar'
import LoginDetails from './DriverLoginDetails'
import Footer from './Footer'

const DriverLogin = () => {
  return (
    <>
    <Navbar/>
    <LoginDetails/>
    <Footer/>
    </>
  )
}

export default DriverLogin