import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ViewTrip = () => {
	const [trip, setTrip] = useState(null);
	const { tripId } = useParams(); // Retrieve trip ID from route params

	useEffect(() => {
		const fetchTrip = async () => {
			const token = localStorage.getItem("token");

			try {
				const response = await axios.get(
					`https://api.rive.ng/api/v1/rive/riveDetails/${tripId}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setTrip(response.data.rive);
			} catch (error) {
				toast.error("Error fetching trip:", error);
			}
		};

		if (tripId) {
			fetchTrip();
		}
	}, [tripId]);

	if (!trip) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<h2>View Trip</h2>
			<p>Trip ID: {trip._id}</p>
			<p>Pickup Location: {trip.origin && trip.origin.desc}</p>
			<p>Drop-off Location: {trip.destination && trip.destination.desc}</p>
			<p>Date: {new Date(trip.createdAt).toLocaleString()}</p>
			{/* Add any other information you want to display */}
		</div>
	);
};

export default ViewTrip;
