import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../AuthContext";

const Login = () => {
	const navigate = useNavigate(); // Hook for navigation
	const { setToken } = useAuthentication();
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	// console.log({ token, isLoggedin });
	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		const credentials = {
			email: username,
			password: password,
		};

		try {
			const response = await axios.post(
				"https://api.rive.ng/api/v1/auth/driver/login",
				credentials
			);
			const token = response.data.token;
			localStorage.setItem("token", token);
			setToken(token);
			// setToken(() => {
			// 	localStorage.setItem("token", token);
			// });

			if (response.status === 200) {
				// Authentication successful
				toast.success("Admin sign-in successful", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});

				// Navigate to /admin/ on successful login
				navigate("/admin/");
			} else {
				// Authentication failed
				toast.error("Admin sign-in failed", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} catch (error) {
			// Handle error, display toast, etc.
			toast.error("An error occurred during authentication", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<section className="adminLogin">
				<div className="loginCenter">
					<h1>Admin Login!</h1>
					<form onSubmit={handleLogin}>
						<label htmlFor="username">Username</label>
						<input
							type="text"
							name="username"
							id="username"
							placeholder="John"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
						<label htmlFor="password">Password</label>
						<input
							type="password"
							name="password"
							id="password"
							placeholder="Enter Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button type="submit" className="adminSearch" disabled={loading}>
							{loading ? "Logging in..." : "Login"}
						</button>
					</form>
				</div>
				<ToastContainer />
			</section>
		</>
	);
};

export default Login;
