import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import AboutUs from "./AboutUs";
import App from "../app";
import Download from "./Download";
import Contact from "./Contact";
import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import Faq from "./Faq";
import TermsOfUse from "./TermsOfUse";
import Error from "./Error";
import UserVerify from "./UserVerify";
import DriverInfoSignUp from "./DriverInfoSignUp";
import ContactInfoSignUp from "./ContactInfoSignUp";
import UserImageDetails from "./UserImageDetails";
import UploadDocument from "./UploadDocument";
import Dashboard from "../admin/components/Dashboard";
import DriverDetails from "../admin/components/DriverDetails";
import Drivers from "../admin/components/Drivers";
import Payments from "../admin/components/Payments";
import Trips from "../admin/components/Trips";
import UserDetails from "../admin/components/UserDetails";
import Users from "../admin/components/Users";
import DashboardContent from "../admin/components/DashboardContent";
import Inbox from "../admin/components/Inbox";
import Settings from "../admin/components/Settings";
import Deliveries from "../admin/components/Deliveries";
import Login from "../admin/components/Login";
import DriverLogin from "./DriverLogin";
import DriverDashboard from "../driver/components/DriverDashboard";
import DriverHome from "../driver/components/DriverHome";
import DriverProfile from "../driver/components/DriverProfile";
import DriverSettings from "../driver/components/DriverSettings";
import DriverDocs from "../driver/components/DriverDocs";
import EditUser from "../admin/components/EditUser";
import ViewUser from "../admin/components/ViewUser";
import ViewDriver from "../admin/components/ViewDriver";
import EditDriver from "../admin/components/EditDriver";
import ViewTrip from "../admin/components/ViewTrip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../AuthContext";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordOTP from "./ForgotPasswordOTP";
import ResetPassword from "./ResetPassword";

const ReactRouterSetup = () => {
	return (
		<BrowserRouter>
			<AuthContext>
				<ToastContainer />
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="about-us" element={<AboutUs />} />
					<Route path="download" element={<Download />} />
					<Route path="contact" element={<Contact />} />
					<Route path="cookie-policy" element={<CookiePolicy />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route path="faq" element={<Faq />} />
					<Route path="terms-of-use" element={<TermsOfUse />} />
					<Route path="user-verify" element={<UserVerify />} />
					<Route path="user-verify-info" element={<DriverInfoSignUp />} />
					<Route path="user-verify-contact" element={<ContactInfoSignUp />} />
					<Route path="user-verify-pics" element={<UserImageDetails />} />
					<Route path="user-verify-docs" element={<UploadDocument />} />
					<Route path="admin-login" element={<Login />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="forgot-verify" element={<ForgotPasswordOTP />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="login" element={<DriverLogin />} />
					<Route path="*" element={<Error />} />
					<Route path="admin" element={<Dashboard />}>
						<Route index element={<DashboardContent />} />
						<Route path="driver-details" element={<DriverDetails />} />
						<Route path="drivers" element={<Drivers />} />
						<Route path="payments" element={<Payments />} />
						<Route path="trips" element={<Trips />} />
						<Route path="user-detail" element={<UserDetails />} />
						<Route path="users" element={<Users />} />
						<Route path="inbox" element={<Inbox />} />
						<Route path="settings" element={<Settings />} />
						<Route path="deliveries" element={<Deliveries />} />
						<Route path="edit/:email" element={<EditUser />} />
						<Route path="view/:email" element={<ViewUser />} />
						<Route path="view-driver/:id" element={<ViewDriver />} />
						<Route path="edit-driver/:id" element={<EditDriver />} />
						<Route path="view-trip/:tripId" element={<ViewTrip />} />
					</Route>
					<Route path="driver" element={<DriverDashboard />}>
						<Route index element={<DriverHome />} />
						<Route path="profile" element={<DriverProfile />} />
						<Route path="settings" element={<DriverSettings />} />
						<Route path="docs" element={<DriverDocs />} />
						<Route path="inbox" element={<Inbox />} />
					</Route>
				</Routes>
			</AuthContext>
		</BrowserRouter>
	);
};

export default ReactRouterSetup;
