import React from 'react'
import book from '../Images/book.svg'
import general from '../Images/message-question.svg'
import booking from '../Images/receipt-2.svg'
import privacy from '../Images/shield-tick.svg'


function Categories({filterItems}) {
  return (
    <div className='container'>
      <div className="buttonwrapper">
       <div className='but' onClick={()=> filterItems('all')}> <div className='cat'> <img src={book} alt="" /></div> <p>All</p></div>
       <div className='but' onClick={()=> filterItems('General Questions')}> <div className='cat'> <img src={general} alt="" /></div> <p>General Questions</p></div>
       <div className='but' onClick={()=> filterItems('Booking and Rides')}> <div className='cat'> <img src={booking} alt="" /></div> <p>Booking and Rides</p></div>
       <div className='but' onClick={()=> filterItems('Privacy and Data')}> <div className='cat'> <img src={privacy} alt="" /></div> <p>Privacy and Data</p></div>
      </div>
    </div>
  )
}

export default Categories 

