import React from 'react'
import image from '../Images/errorimage.jpg'
import { Link } from 'react-router-dom'

function ErrorContents() {
  return (
    <React.Fragment>
      <section className="features errs">
      <div className="container err" id="features">
        <div className="text errortxt"><h1>Oops! The Road Less Traveled</h1>
          <p>While the path may seem a bit elusive, our tech-savvy team is already working behind the scenes to guide you back to smoother digital highways. In the meantime, feel free to explore other facets of Rive. Happy navigating!</p>

          <div className="btn">

          <Link to='/download'><button type="button">Download the Rive App</button></Link>
          <Link to=''><button type="button" className='secondary'>Back to Homepage</button></Link>
          </div>
          </div>
          <div className='errorimg'>
          <img src={image} alt="Image" loading="lazy"/>
          </div>
      </div>
      </section>
    </React.Fragment>
  )
}

export default ErrorContents