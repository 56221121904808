import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ResetPassword = () => {
	const location = useLocation();
	const param = new URLSearchParams(location.search);
	const email = param.get("email");
	const otp = param.get("otp");
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const BASE_URL = `https://api.rive.ng/api/v1`;

	useEffect(() => {
		if (email || otp === null) {
			navigate("/");
		}
	}, [navigate]);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.patch(
				`${BASE_URL}/auth/driver/reset-password`,
				{ email, password, confirmPassword, otp }
			);
			toast.success(data.msg);
			navigate("/login");
		} catch (error) {
			toast.error("Unable to reset password now");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="topDown">
			<Navbar />
			<div className="newLayout">
				<h1>Reset Password</h1>
				<p style={{ marginTop: "4px" }}>
					Enter Email address to reset your password
				</p>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "24px",
					}}
				>
					<label htmlFor="password">Password</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Enter Password"
						className="longPassword"
						value={password}
						onChange={(e) => {
							setPassword(e.currentTarget.value);
						}}
						style={{ marginTop: "16px" }}
					/>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "24px",
					}}
				>
					<label htmlFor="re enter password">Confirm password</label>
					<input
						type="password"
						name="re enter password"
						id="re enter password"
						placeholder="Enter Password"
						className="longPassword"
						value={confirmPassword}
						onChange={(e) => {
							setConfirmPassword(e.currentTarget.value);
						}}
						style={{ marginTop: "16px" }}
					/>
				</div>
				<br />
				<button
					style={{ marginTop: "24px" }}
					disabled={isLoading}
					onClick={handleSubmit}
				>
					{isLoading ? "Resetting Password" : "Reset Password"}
				</button>
			</div>
			<Footer />
		</div>
	);
};

export default ResetPassword;
