import React from 'react'
import ErrorNav from './ErrorNav'
import Footer from './Footer'
import ErrorContents from './ErrorContents'

function Error() {

  return(
  <React.Fragment>
    <ErrorNav/>
    <ErrorContents/>
    <Footer/>
  </React.Fragment>
  )
}

export default Error