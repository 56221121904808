import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import FaqContent from './FaqContent'


function Faq() {
  return (
    <React.Fragment>
      <Navbar/>
      <FaqContent/>
      <Footer/>
    </React.Fragment>
  )
}

export default Faq