import React from 'react'
import { Link } from 'react-router-dom'
import left from '../Images/left.svg'

const ContactInfoDetails = () => {
  return (
    <section className="container reg">
      <Link to="/user-verify-info" className='outline'> <button type="button" className='outline'><img src={left} alt="" /></button></Link>
      <div className='heads'>
        <h1>Contact Details</h1>
        <p>So it's easy to reach you</p>
      </div>

    <div>
        <form className="regwrapper">
      
      <div className='formregwrap names'>  
        <div className='names'>  
          <label htmlFor="PhoneNumber">Phone Number:</label>
          <input type="tel" name="PhoneNumber" id="PhoneNumber" placeholder='+234'/> 
        </div>
      
    </div>  

    <Link to="/user-verify-pics"><button type="submit" className='utility'>Continue</button></Link>
  </form>
      </div>
    </section>
  )
}

export default ContactInfoDetails