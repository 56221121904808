import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPasswordOTP = () => {
	const location = useLocation();
	const param = new URLSearchParams(location.search);
	const email = param.get("email");
	const [otp, setOtp] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const BASE_URL = `https://api.rive.ng/api/v1`;

	useEffect(() => {
		if (email === null) {
			navigate("/");
		}
	}, [navigate]);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const { data } = await axios.post(`${BASE_URL}/auth/driver/verify-otp`, {
				email,
				otp,
			});
			toast.success(data.msg);
			navigate(
				`/reset-password?email=${encodeURIComponent(email)}?otp=${encodeURI(
					otp
				)}`
			);
		} catch (error) {
			toast("Unable to verify otp");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="topDown">
			<Navbar />
			<div className="newLayout">
				<h1>Verify OTP</h1>
				<p style={{ marginTop: "4px" }}>Enter OTP sent to your email</p>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "24px",
					}}
				>
					<label htmlFor="otp">Enter OTP</label>
					<input
						type="text"
						name="otp"
						id="otp"
						placeholder="01234"
						className="longPassword"
						style={{ marginTop: "16px" }}
						value={otp}
						onChange={(e) => {
							setOtp(e.currentTarget.value);
						}}
					/>
				</div>
				<br />
				<button
					style={{ marginTop: "24px" }}
					onClick={handleSubmit}
					disabled={isLoading}
				>
					{isLoading ? "Verifying OTP" : "Verify OTP"}
				</button>
			</div>
			<Footer />
		</div>
	);
};

export default ForgotPasswordOTP;
