import React, {useEffect} from 'react'


function DownloadHero() {

useEffect(()=>{
  document.title = 'Rive | Download Your Reliable Logistics Solution in Abeokuta';
}, [])

  return (
    <React.Fragment>
      <section className="hero-download">
    <div className="container contain">
      
    </div>
  </section>
    </React.Fragment>
  )
}

export default DownloadHero