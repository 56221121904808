import React from 'react'
import ImageS from '../../Images/Image 3.jpg'

const Inbox = () => {
  return (
    <>
      <div id="maintenance-page">
	      <h1>Temporary Pause for 
        <br/>
        Perfection.</h1>
        <br />
	      <img src={ImageS} alt='Maintenance' className='ment'/>
	<p className='ments'>We apologize for the inconvenience! Our team is hard at work behind the scenes to bring this service back to life. Stay tuned, and we'll have it up and running for you in no time. Your journey with Rive is our top priority. Thanks for your patience! </p>
</div>
    </>
  )
}

export default Inbox