import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import DownloadHero from './DownloadHero'
import DownloadContents from './DownloadContents'

function Download() {
  return (
    <React.Fragment>
      <Navbar/>
      <DownloadHero/>
      <DownloadContents/>
      <Footer/>
    </React.Fragment>
  )
}

export default Download