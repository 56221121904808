// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';

// function UserDocumentDetails() {
//   const [documentType, setDocumentType] = useState('NIN');
//   const [documents, setDocuments] = useState([]);
//   const [documentFile, setDocumentFile] = useState(null);
//   const navigate = useNavigate();

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setDocumentFile(file);
//   };

//   const handleTypeChange = (e) => {
//     setDocumentType(e.target.value);
//   };

//   const isDocumentTypeUploaded = (type) => {
//     return documents.some((doc) => doc.type === type);
//   };

//   const handleUpload = (e) => {
//     e.preventDefault();

//     if (!documentFile) {
//       toast.error('Please upload a document', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return;
//     }

//     if (isDocumentTypeUploaded(documentType)) {
//       toast.error(`You have already uploaded a ${documentType}`, {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return;
//     }

//     setDocuments([...documents, { type: documentType, file: documentFile }]);
//     setDocumentFile(null);
//   };

//   const handleRemove = (index) => {
//     const updatedDocuments = [...documents];
//     updatedDocuments.splice(index, 1);
//     setDocuments(updatedDocuments);
//   };

//   const handleProceed = async (e) => {
//     e.preventDefault();

//     if (documents.length === 0) {
//       toast.error('Please upload at least one document', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return;
//     }

//     const token = localStorage.getItem('token');
//     const id = localStorage.getItem('id');

//     const formData = new FormData();

//     // Iterate through documents and append each one
//     documents.forEach((doc, index) => {
//       const key = doc.type.toLowerCase() === 'nin' ? 'nin' : 'driverLicense';
//       formData.append(key, doc.file);
//     });

//     const config = {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     try {
//       const updatedResponse = await axios.patch(
//         `https://rive-backend.vercel.app/api/v1/auth/driver/${id}`,
//         formData,
//         config
//       );

//       toast.success('Documents uploaded successfully', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });

//       console.log('Server response:', updatedResponse);

//       // navigate('/user-success-page');
//     } catch (error) {
//       console.error('Error during document upload:', error);
//       toast.error('An error occurred during document upload', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//     }
//   };

//   return (
//     <section className="container reg">
//       <div className='heads'>
//         <h1>Upload Your Documents</h1>
//         <p>Choose document type and upload</p>
//       </div>

//       <div>
//         <form className="regwrapper">
//           <div className='formregwrap names'>
//             <div className='names'>
//               <label htmlFor="DocumentType">Select Document Type:</label>
//               <select
//                 id="DocumentType"
//                 name="DocumentType"
//                 value={documentType}
//                 onChange={handleTypeChange}
//               >
//                 <option value="NIN">NIN</option>
//                 <option value="DriverLicense">Driver's License</option>
//               </select>
//             </div>

//             <div className='names'>
//               <label htmlFor="Document">Upload Document:</label>
//               <input
//                 type="file"
//                 name="Document"
//                 id="Document"
//                 accept='image/*, application/pdf'
//                 onChange={handleFileChange}
//               />
//             </div>
//           </div>

//           <button type="button" className='utility' onClick={handleUpload}>
//             Upload
//           </button>
//         </form>

//         {documents.length > 0 && (
//           <div className="document-table">
//             <h2>Uploaded Documents</h2>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Document Type</th>
//                   <th>Document Name</th>
//                   <th>Remove</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {documents.map((doc, index) => (
//                   <tr key={index}>
//                     <td>{doc.type}</td>
//                     <td>{doc.file.name}</td>
//                     <td>
//                       <button onClick={() => handleRemove(index)}>Remove</button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}

//         {documents.length > 0 && (
//           <form className="regwrapper" onSubmit={handleProceed}>
//             <button type="submit" className='utility'>
//               Proceed
//             </button>
//           </form>
//         )}
//       </div>
//       <ToastContainer />
//     </section>
//   );
// }

// export default UserDocumentDetails;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function UserDocumentDetails() {
	const [loading, setLoading] = useState(false);
	const [documentType, setDocumentType] = useState("NIN");
	const [documents, setDocuments] = useState([]);
	const [documentFile, setDocumentFile] = useState(null);
	const navigate = useNavigate();

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setDocumentFile(file);
	};

	const handleTypeChange = (e) => {
		setDocumentType(e.target.value);
	};

	const handleUpload = (e) => {
		e.preventDefault();

		if (!documentFile) {
			toast.error("Please upload a document", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			return;
		}

		if (documents.some((doc) => doc.type === documentType)) {
			toast.error(`You have already uploaded a ${documentType}`, {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			return;
		}

		setDocuments([...documents, { type: documentType, file: documentFile }]);
		setDocumentFile(null);
	};

	const handleRemove = (index) => {
		const updatedDocuments = [...documents];
		updatedDocuments.splice(index, 1);
		setDocuments(updatedDocuments);
	};

	const handleProceed = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (documents.length === 0) {
			toast.error("Please upload at least one document", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setLoading(false);
			return;
		}

		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");

		const formData = new FormData();

		const ninDoc = documents.find((doc) => doc.type.toLowerCase() === "nin");
		const dlDoc = documents.find(
			(doc) => doc.type.toLowerCase() === "driverlicense"
		);

		try {
			if (ninDoc) {
				formData.append("nin", ninDoc.file);
				const ninConfig = {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				};
				const ninResponse = await axios.patch(
					`https://rive-backend.vercel.app/api/v1/auth/driver/${id}`,
					formData,
					ninConfig
				);
				toast.success("NIN document uploaded successfully", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				// console.log("NIN Upload Response:", ninResponse);
			}

			if (dlDoc) {
				formData.delete("nin"); // Remove NIN data if present
				formData.append("driverLicense", dlDoc.file);
				const dlConfig = {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				};
				const dlResponse = await axios.patch(
					`https://rive-backend.vercel.app/api/v1/auth/driver/${id}`,
					formData,
					dlConfig
				);
				toast.success("Driver's License document uploaded successfully", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				// console.log("Driver's License Upload Response:", dlResponse);
			}

			navigate("/driver");
		} catch (error) {
			// console.error("Error during document upload:", error);
			toast.error("An error occurred during document upload", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="container reg">
			<div className="heads">
				<h1>Upload Your Documents</h1>
				<p>Choose document type and upload</p>
			</div>

			<div>
				<form className="regwrapper">
					<div className="formregwrap names">
						<div className="names">
							<label htmlFor="DocumentType">Select Document Type:</label>
							<select
								id="DocumentType"
								name="DocumentType"
								value={documentType}
								onChange={handleTypeChange}
							>
								<option value="NIN">NIN</option>
								<option value="DriverLicense">Driver's License</option>
							</select>
						</div>

						<div className="names">
							<label htmlFor="Document">Upload Document:</label>
							<input
								type="file"
								name="Document"
								id="Document"
								accept="image/*, application/pdf"
								onChange={handleFileChange}
							/>
						</div>
					</div>

					<button type="button" className="utility" onClick={handleUpload}>
						Upload
					</button>
				</form>

				{documents.length > 0 && (
					<div className="document-table">
						<h2>Uploaded Documents</h2>
						<table>
							<thead>
								<tr>
									<th>Document Type</th>
									<th>Document Name</th>
									<th>Remove</th>
								</tr>
							</thead>
							<tbody>
								{documents.map((doc, index) => (
									<tr key={index}>
										<td>{doc.type}</td>
										<td>{doc.file.name}</td>
										<td>
											<button onClick={() => handleRemove(index)}>
												Remove
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}

				{documents.length > 0 && (
					<form className="regwrapper" onSubmit={handleProceed}>
						<button type="submit" className="utility">
							{loading ? "Uploading..." : "Proceed"}
						</button>
					</form>
				)}
			</div>
			<ToastContainer />
		</section>
	);
}

export default UserDocumentDetails;
