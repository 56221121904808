import React, { useState, useEffect } from "react";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const LoadingSpinner = () => (
	<div className="loading-spinner">
		<FontAwesomeIcon icon={faSpinner} spin />
	</div>
);

const Payments = () => {
	const [loading, setLoading] = useState(true);
	const [payments, setPayments] = useState([]);
	const [sortOrder, setSortOrder] = useState({
		column: "email",
		direction: "asc",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;

	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem("token");
			try {
				const response = await axios.get(
					"https://api.rive.ng/api/v1/wallet/all-wallet-transactions",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setPayments(response.data.transactions);
				setLoading(false);
				// Assuming the API response has a 'transactions' property
			} catch (error) {
				toast.error("Error fetching payments");
			}
		};

		fetchData();
	}, []);

	const columnValue = (payment, column) => {
		if (!payment || !column) {
			return "";
		}

		// Adjust column names to match the API response
		const columnNameMapping = {
			transactionId: "_id",
			paymentChannel: "label",
			date: "createdAt",
			// Add other mappings as needed
		};

		const apiColumnName = columnNameMapping[column] || column;
		const value = payment[apiColumnName];

		if (column === "amount") {
			// Format 'amount' as a monetary value with two decimal places
			return Number(value).toLocaleString("en-NG", {
				style: "currency",
				currency: "NGN",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
		}

		return String(value).toLowerCase();
	};

	const handleSort = (column) => {
		setSortOrder((prevSortOrder) => ({
			column,
			direction:
				prevSortOrder.column === column && prevSortOrder.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const sortedPayments = [...payments].sort((a, b) => {
		const columnA = columnValue(a, sortOrder.column).toLowerCase();
		const columnB = columnValue(b, sortOrder.column).toLowerCase();

		if (columnA < columnB) {
			return sortOrder.direction === "asc" ? -1 : 1;
		}
		if (columnA > columnB) {
			return sortOrder.direction === "asc" ? 1 : -1;
		}
		return 0;
	});

	const renderSortIcon = (column) => {
		const isActive = sortOrder.column === column;
		const arrow = sortOrder.direction === "asc" ? "▲" : "▼";

		return (
			<span className="adminSearchIcon" onClick={() => handleSort(column)}>
				{isActive ? arrow : ""}
			</span>
		);
	};

	// Paginate the payments
	const indexOfLastPayment = currentPage * itemsPerPage;
	const indexOfFirstPayment = indexOfLastPayment - itemsPerPage;
	const currentPayments = sortedPayments.slice(
		indexOfFirstPayment,
		indexOfLastPayment
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div>
			<div className="brox">
				{loading ? (
					<LoadingSpinner />
				) : (
					<table>
						<thead>
							<tr>
								<th className="adminclick" onClick={() => handleSort("email")}>
									Email {renderSortIcon("email")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("transactionId")}
								>
									Transaction ID {renderSortIcon("transactionId")}
								</th>
								<th className="adminclick" onClick={() => handleSort("amount")}>
									Price {renderSortIcon("amount")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("paymentChannel")}
								>
									Payment Channel {renderSortIcon("paymentChannel")}
								</th>
								<th className="adminclick" onClick={() => handleSort("date")}>
									Date {renderSortIcon("date")}
								</th>
							</tr>
						</thead>
						<tbody>
							{currentPayments.map((payment) => (
								<tr key={payment._id}>
									<td>{payment.user.email}</td>
									<td>{payment._id}</td>
									<td>{columnValue(payment, "amount")}</td>
									<td>{columnValue(payment, "paymentChannel")}</td>
									<td>{columnValue(payment, "date")}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			<div className="paginations">
				{Array.from({
					length: Math.ceil(sortedPayments.length / itemsPerPage),
				}).map((_, index) => (
					<button
						key={index}
						className={
							currentPage === index + 1 ? "adminSearch" : "secondarySearcher"
						}
						onClick={() => paginate(index + 1)}
					>
						{index + 1}
					</button>
				))}
			</div>
		</div>
	);
};

export default Payments;
