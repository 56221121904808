import React from 'react'
import ErrorNav from './ErrorNav'
import DriverInfoDetails from './DriverInfoDetails'
import ProgressBar from './ProgressBar'


function DriverInfoSignUp() {
  return (
    <>
      <ErrorNav/>
      <ProgressBar/>
      <DriverInfoDetails/>
    </>
  )
}

export default DriverInfoSignUp