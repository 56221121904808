import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import left from '../Images/left.svg';
import axios from "axios";

const DriverInfoDetails = () => {
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!firstName || !lastName || !phoneNumber) {
			toast.error("Please fill in all fields", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setLoading(false);
			return;
		}

		const requestBody = {
			firstName,
			lastName,
			tel: phoneNumber,
		};

		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");
		try {
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${id}`,
				requestBody,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const msg = response?.data?.msg;

			toast.success(msg || "Driver information updated successfully", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			navigate("/user-verify-pics");
		} catch (error) {
			const msg = error?.response?.data?.msg;

			toast.error("Error updating driver information");
			toast.error(
				msg || "An error occurred while updating driver information",
				{
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				}
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="container reg">
			{/* <Link to="/user-verify" className="outline">
        <button type="button" className="outline">
          <img src={left} alt="" />
        </button>
      </Link> */}
			<div className="heads">
				<h1>What's your name</h1>
				<p>Let's get to know you</p>
			</div>

			<div>
				<form className="regwrapper" onSubmit={handleSubmit}>
					<div className="formregwrap names">
						<div className="names">
							<label htmlFor="FirstName">First Name:</label>
							<input
								type="text"
								name="Firstname"
								id="FirstName"
								placeholder="John"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>

						<div className="names">
							<label htmlFor="LastName">Last Name:</label>
							<input
								type="text"
								name="Lastname"
								id="LastName"
								placeholder="Doe"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>

						<div className="names">
							<label htmlFor="PhoneNumber">Phone Number:</label>
							<input
								type="tel"
								name="PhoneNumber"
								id="PhoneNumber"
								placeholder="+234"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
						</div>
					</div>

					<button type="submit" className="utility">
						{loading ? "Processing..." : "Continue"}
					</button>
				</form>
			</div>
			<ToastContainer />
		</section>
	);
};

export default DriverInfoDetails;
