import React, { useState, useEffect } from "react";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DriverHome = ({ verificationStatus }) => {
	const [ridesData, setRidesData] = useState(null);

	useEffect(() => {
		const fetchRidesData = async () => {
			try {
				const token = localStorage.getItem("token");
				const id = localStorage.getItem("id");

				// Fetch rides data
				const responseRides = await axios.get(
					`https://api.rive.ng/api/v1/rive/driver/Rives/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const ridesData = responseRides.data;
				// console.log(ridesData);

				setRidesData(ridesData);
			} catch (error) {
				// console.log("Error fetching rides data from the API", error);
			}
		};

		fetchRidesData();
	}, [verificationStatus]); // Include verificationStatus in the dependency array if needed

	if (!ridesData) {
		return (
			<div className="loading-spinner">
				<FontAwesomeIcon icon={faSpinner} spin />
			</div>
		);
	}

	// Filter rides based on trip status
	const getTripsCount = (status) => {
		return (
			ridesData.rives?.filter((ride) => ride.tripStatus === status).length || 0
		);
	};

	return (
		<div className="datapreview">
			<h2>Rides Details</h2>
			<p>
				<strong>Total Trips:</strong>{" "}
				{ridesData.rives ? ridesData.rives.length : "Loading..."}
			</p>
			<p>
				<strong>Completed Trips:</strong> {getTripsCount("completed")}
			</p>
			<p>
				<strong>Canceled Trips:</strong> {getTripsCount("cancelled")}
			</p>
			<p>
				<strong>Pending Trips:</strong> {getTripsCount("pending")}
			</p>

			{/* Check if ridesData.rives is defined before mapping over it */}
			{ridesData.rives && ridesData.rives.length > 0 ? (
				ridesData.rives.map((ride, index) => (
					<div key={index}>{/* You can add other ride details here */}</div>
				))
			) : (
				// Display message if there are no rides or still loading
				<p>{ridesData.rives ? "No rides available." : "Loading..."}</p>
			)}
		</div>
	);
};

export default DriverHome;
