import React, { createContext, useContext, useEffect, useState } from "react";

const authContext = createContext();

const AuthContext = ({ children }) => {
	// const storedToken = localStorage.getItem("token");
	// const [token, setToken] = useState(storedToken || "");
	// const [isLoggedin, setIsLoggedin] = useState(!!storedToken);

	const [token, setToken] = useState(() => {
		return localStorage.getItem("token") || "";
	});
	const [isLoggedin, setIsLoggedin] = useState(!!token);

	// useEffect(() => {
	// 	localStorage.setItem("token", token);
	// 	setIsLoggedin(!!token);
	// }, [token]);

	useEffect(() => {
		setIsLoggedin(!!token);
	}, [token]);
	return (
		<authContext.Provider
			value={{ token, setToken, isLoggedin, setIsLoggedin }}
		>
			{children}
		</authContext.Provider>
	);
};

export default AuthContext;

export const useAuthentication = () => {
	return useContext(authContext);
};
