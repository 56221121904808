import React, {useState} from 'react'
import Menu from '../Images/menu.svg'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false)
  return (
  <nav>
    <div className="container navigation">  
    <Link to='/' className='logo'> Rive</Link>
      <ul className='linksNav'>
        <li><a href="/#features">Features</a></li>
        <li><Link to='/about-us'> About Us </Link></li>
        <li><Link to='/download'> Download </Link></li>
        <li><Link to='/contact'> Contact </Link></li>
      </ul>
      <a href="/download" className='linksNav'><button type='button'>Download</button></a>
      <button type="button" className='menu' onClick={()=>setNavbar(!navbar)}><img src={Menu} alt="menu"/></button>
    </div>
    {navbar && <ul className='container linksNavs'>
        <li><a href="/#features">Features</a></li>
        <li><Link to='/about-us'> About Us </Link></li>
        <li><Link to='/download'> Download </Link></li>
        <li><Link to='/contact'> Contact </Link></li>
      </ul>}
  </nav>
  );
}

export default Navbar