import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsisV,
	faEye,
	// faEdit,
	faSpinner,
	faTrash,
	faPlay,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const LoadingSpinner = () => (
	<div className="loading-spinner">
		<FontAwesomeIcon icon={faSpinner} spin />
	</div>
);

const DriverOptionsMenu = ({
	driver,
	onClose,
	onAction,
	handleDeactivateDriver,
	handleActivateDriver,
}) => {
	const handleMenuItemClick = async (action, driverId) => {
		onClose();

		if (action === "Review") {
			// You can add specific logic for the 'Review' action here if needed
		} else if (action === "Edit") {
			// Handle Edit action
			// You can perform additional actions specific to 'Edit' here
		} else if (action === "Deactivate") {
			const confirmAction = window.confirm(
				`Are you sure you want to deactivate this driver?`
			);
			if (confirmAction) {
				await handleDeactivateDriver(driverId);
				onAction(action);
			}
		} else if (action === "Activate") {
			const confirmAction = window.confirm(
				`Are you sure you want to activate this driver?`
			);
			if (confirmAction) {
				await handleActivateDriver(driverId);
				onAction(action);
			}
		}
	};

	return (
		<div className="popupMenu">
			<Link
				to={`/admin/view-driver/${driver._id}`}
				onClick={() => handleMenuItemClick("Review", driver._id)}
			>
				<FontAwesomeIcon icon={faEye} /> Review Driver
			</Link>

			{/* <Link
        to={`/admin/edit-driver/${driver._id}`}
        onClick={() => handleMenuItemClick('Edit', driver._id)}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit Driver
      </Link> */}

			{driver.isActive ? (
				<div onClick={() => handleMenuItemClick("Deactivate", driver._id)}>
					<FontAwesomeIcon icon={faTrash} /> Deactivate Driver
				</div>
			) : (
				<div onClick={() => handleMenuItemClick("Activate", driver._id)}>
					<FontAwesomeIcon icon={faPlay} /> Activate Driver
				</div>
			)}
		</div>
	);
};

const Drivers = () => {
	const [loading, setLoading] = useState(true);
	const [drivers, setDrivers] = useState([]);
	const [sortOrder, setSortOrder] = useState({
		column: "firstName",
		direction: "asc",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedDriver, setSelectedDriver] = useState(null);
	const [openDropdowns, setOpenDropdowns] = useState({});
	const itemsPerPage = 10;

	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem("token");
			try {
				const response = await axios.get(
					"https://api.rive.ng/api/v1/auth/driver/all",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setDrivers(response.data.drivers);
				setLoading(false);
			} catch (error) {
				toast.error("Error fetching drivers");
			}
		};

		fetchData();
	}, []);

	const columnValue = (driver, column) => {
		if (!driver || !column) {
			return "";
		}

		const columnNameMapping = {
			status: "isVerified",
			phone: "tel",
			// Add other mappings as needed
		};

		const apiColumnName = columnNameMapping[column] || column;
		const value = driver[apiColumnName];

		if (column === "status" && typeof value === "boolean") {
			return value ? "verified" : "not verified";
		}

		return String(value).toLowerCase();
	};

	const handleSort = (column) => {
		setSortOrder((prevSortOrder) => ({
			column,
			direction:
				prevSortOrder.column === column && prevSortOrder.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const handleDeactivateDriver = async (driverId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${driverId}`,
				{ isActive: false },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				const updatedDrivers = await axios.get(
					"https://api.rive.ng/api/v1/auth/driver/all",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);
				toast.success("Driver deactivated successfully!");
				setDrivers(updatedDrivers.data.drivers);
			} else {
				toast.error(`Failed to deactivate driver. Status: ${response.status}`);
			}
		} catch (error) {
			toast.error("Error deactivating driver");
		}
	};

	const handleActivateDriver = async (driverId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.patch(
				`https://api.rive.ng/api/v1/auth/driver/${driverId}`,
				{ isActive: true },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				toast.success("Driver activated successfully!");
				const updatedDrivers = await axios.get(
					"https://rive-backend.vercel.app/api/v1/auth/driver/all",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				setDrivers(updatedDrivers.data.drivers);
			} else {
				toast.error(`Failed to activate driver`);
			}
		} catch (error) {
			toast.error("Error activating driver");
		}
	};

	const handleActionClick = async (driver) => {
		if (driver && driver._id) {
			if (openDropdowns[driver._id]) {
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[driver._id]: false,
				}));
			} else {
				if (Object.keys(openDropdowns).length > 0) {
					setOpenDropdowns({});
				}

				setSelectedDriver(driver);
				setOpenDropdowns((prevOpenDropdowns) => ({
					...prevOpenDropdowns,
					[driver._id]: true,
				}));
			}
		} else {
			toast.error("Invalid driver object");
		}
	};

	const handleCloseDropdown = () => {
		setOpenDropdowns({});
		setSelectedDriver(null);
	};

	const renderSortIcon = (column) => {
		const isActive = sortOrder.column === column;
		const arrow = sortOrder.direction === "asc" ? "▲" : "▼";

		return (
			<span className="adminSearchIcon" onClick={() => handleSort(column)}>
				{isActive ? arrow : ""}
			</span>
		);
	};

	const indexOfLastDriver = currentPage * itemsPerPage;
	const indexOfFirstDriver = indexOfLastDriver - itemsPerPage;
	const sortedDrivers = [...drivers].sort((a, b) => {
		const columnA = columnValue(a, sortOrder.column).toLowerCase();
		const columnB = columnValue(b, sortOrder.column).toLowerCase();

		if (columnA < columnB) {
			return sortOrder.direction === "asc" ? -1 : 1;
		}
		if (columnA > columnB) {
			return sortOrder.direction === "asc" ? 1 : -1;
		}
		return 0;
	});
	const currentDrivers = sortedDrivers.slice(
		indexOfFirstDriver,
		indexOfLastDriver
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div>
			<div className="brox">
				{loading ? (
					<LoadingSpinner />
				) : (
					<table>
						<thead>
							<tr>
								<th
									className="adminclick"
									onClick={() => handleSort("firstName")}
								>
									Firstname {renderSortIcon("firstName")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("lastName")}
								>
									Lastname {renderSortIcon("lastName")}
								</th>
								<th className="adminclick" onClick={() => handleSort("email")}>
									Email {renderSortIcon("email")}
								</th>
								<th className="adminclick" onClick={() => handleSort("status")}>
									Verification Status {renderSortIcon("status")}
								</th>
								<th className="adminclick" onClick={() => handleSort("phone")}>
									Phone Number {renderSortIcon("phone")}
								</th>
								<th
									className="adminclick"
									onClick={() => handleSort("driverStatus")}
								>
									Driver Status {renderSortIcon("driverStatus")}
								</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentDrivers.map((driver) => (
								<tr key={driver._id}>
									<td>{driver.firstName}</td>
									<td>{driver.lastName}</td>
									<td>{driver.email}</td>
									<td>{columnValue(driver, "status")}</td>
									<td>{columnValue(driver, "phone")}</td>
									<td>{driver.isActive ? "Active" : "Suspended"}</td>
									<td>
										<button
											className={`actionButton ${
												driver.isActive ? "activateButton" : "deactivateButton"
											}`}
											onClick={() => handleActionClick(driver)}
										>
											<FontAwesomeIcon icon={faEllipsisV} />
										</button>
										{openDropdowns[driver._id] && (
											<div className="overlay">
												<DriverOptionsMenu
													driver={selectedDriver}
													onClose={handleCloseDropdown}
													onAction={() => handleActionClick(selectedDriver)}
													handleDeactivateDriver={handleDeactivateDriver}
													handleActivateDriver={handleActivateDriver}
												/>
											</div>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			<div className="paginations">
				{Array.from({
					length: Math.ceil(sortedDrivers.length / itemsPerPage),
				}).map((_, index) => (
					<button
						key={index}
						className={
							currentPage === index + 1 ? "adminSearch" : "secondarySearcher"
						}
						onClick={() => paginate(index + 1)}
					>
						{index + 1}
					</button>
				))}
			</div>
		</div>
	);
};

export default Drivers;
