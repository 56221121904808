import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProgressBar = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// Map the URL paths to steps
		const pathToStep = {
			"/user-verify-info": 1,
			"/user-verify-pics": 2,
			"/user-verify-docs": 3,
		};

		// Get the current step based on the current location
		const step = pathToStep[location.pathname] || 1;

		// If the user tries to access step 1 and they're already past it, redirect them
		if (step === 1 && currentStep > 1) {
			navigate("/user-verify-pics"); // Redirect to step 2 if they try to access step 1
		}

		setCurrentStep(step);
	}, [location, currentStep, navigate]);

	const handleStepClick = (step) => {
		// Only allow navigation if the clicked step is completed and not step 1 if they're past it
		if (step <= currentStep && !(step === 1 && currentStep > 1)) {
			navigate(
				`/user-verify-${step === 1 ? "info" : step === 2 ? "pics" : "docs"}`
			);
		}
	};

	const stepNames = ["Driver Details", "Driver Image", "Official Documents"];

	return (
		<div className="progress-bar-container">
			<div className="step-names">
				{stepNames.map((step, index) => {
					const stepIndex = index + 1;
					const isDisabled = stepIndex === 1 && currentStep > 1; // Disable step 1 after it's passed
					return (
						<div
							key={index}
							className={`step-name ${
								currentStep === stepIndex ? "progressActive" : ""
							} ${isDisabled ? "disabled" : ""}`}
							onClick={() => !isDisabled && handleStepClick(stepIndex)} // Disable click if it's step 1 after being passed
							style={{
								cursor: isDisabled ? "not-allowed" : "pointer",
								opacity: isDisabled ? 0.5 : 1,
							}} // Style to indicate it's disabled
						>
							{step}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ProgressBar;
