import React from 'react'

function AboutHero() {
  return (
    <React.Fragment>
      <section className="hero-about">
    <div className="container titles">
      <h1>Welcome to Rive</h1>
      <p>Your Reliable Logistics Solution in Abeokuta, Ogun State!</p>
    </div>
  </section>
    </React.Fragment>
  )
}

export default AboutHero