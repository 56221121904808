import React from 'react'
import ErrorNav from './ErrorNav'
import UserVerifyContent from './UserVerifyContent'

function UserVerify() {
  return (
    <React.Fragment>
      <ErrorNav/>
      <UserVerifyContent/>
    </React.Fragment>
  )
}

export default UserVerify