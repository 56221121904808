import React from 'react';
import ReactDom from 'react-dom';
import { Outlet } from 'react-router-dom';

import ReactRouterSetup from './Components/ReactRouterSetup';
import './index.css'


const Index = ()=> {
  return (
    <React.Fragment>
      <ReactRouterSetup/>
      <Outlet/>
    </React.Fragment>

);
}

ReactDom.render(<Index></Index>, document.getElementById('root'))