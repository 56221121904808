import React from "react";
import logo from "../../Images/Subtract.svg";

const DriverTopNav = ({ pageTitle, pageDescription, verificationStatus }) => {
	return (
		<>
			<div className="topNav">
				<div className="logos">
					<img src={logo} alt="rive" />
					<p>Rive</p>
				</div>
				<div className="leftSide">
					<div className="namesHead">
						<h1>{pageTitle}</h1>
						<p>{pageDescription}</p>
					</div>

					<div className="sided">
						<div className="verificationStatus">
							<p>Verification Status : {verificationStatus}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DriverTopNav;
