import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import left from "../Images/left.svg";

const UserVerifyContent = () => {
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState("");
	const [email, setEmail] = useState("");

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setEmail(queryParams.get("email") || "");
	}, [location]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!otp.match(/^\d{6}$/)) {
			toast.error("Please enter a valid 6-digit OTP", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setLoading(false);
			return;
		}

		const requestBody = {
			otp,
			email,
		};

		try {
			const response = await fetch(
				"https://api.rive.ng/api/v1/auth/driver/verify-otp",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestBody),
				}
			);

			if (response.ok) {
				toast.success("OTP verification successful", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});

				navigate(`/user-verify-pics?email=${encodeURIComponent(email)}`);
			} else {
				toast.error("OTP verification failed", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} catch (error) {
			// console.error("Error during OTP verification:", error);
			toast.error("An error occurred during OTP verification", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="container reg">
			<Link to="/" className="outline">
				<button type="button" className="outline">
					<img src={left} alt="" />
				</button>
			</Link>
			<div className="heads">
				<h1>Let’s Verify You</h1>
				<p>Enter the 6-digit code sent to you at {email}</p>
			</div>

			<div>
				<form className="regwrapper" onSubmit={handleSubmit}>
					<label htmlFor="otp">Enter OTP:</label>
					<input
						type="text"
						id="otp"
						name="otp"
						className="otp-input"
						maxLength="6"
						pattern="[0-9]*"
						required
						value={otp}
						onChange={(e) => setOtp(e.target.value)}
					/>

					<button type="submit" className="utility">
						{loading ? "verifying..." : "Continue"}
					</button>
				</form>
			</div>
			<ToastContainer />
		</section>
	);
};

export default UserVerifyContent;
