const questions = [
  {
  id: 1,
  q: 'What is Rive?',
  a: 'Rive is a logistics company based in Abeokuta, Ogun State, Nigeria. We focus on providing affordable and efficient transportation solutions, including ride-hailing services and deliveries',
  category: 'General Questions'
}, 
  {
  id: 2,
  q: 'Where do you operate?',
  a: 'Currently, we operate in the Abeokuta area of Ogun State, Nigeria. However, we have plans to expand our services to cover more areas in the future',
  category: 'General Questions'
}, 
  {
  id: 3,
  q: 'How can I contact Rive?',
  a: 'You can reach out to us through email at [Email Address], or you can find our contact details on the "Contact" page of our website',
  category: 'General Questions'
}, 
  {
  id: 4,
  q: 'How do I book a ride with Rive?',
  a: 'Booking a ride is simple. Download the Rive app, create an account, and enter your pickup and drop-off locations.',
  category: 'Booking and Rides'
}, 
  {
  id: 5,
  q: 'Is it safe to use Rive for transportation?',
  a: 'Safety is our top priority. Our drivers are vetted, and our vehicles are regularly maintained. You can track your ride in real-time and share your ride details with friends or family for added security.',
  category: 'Booking and Rides'
}, 
  {
  id: 6,
  q: 'What payment methods do you accept?',
  a: 'We accept various payment methods, including credit/debit cards, mobile wallets, and cash. You can choose the most convenient option for you.',
  category: 'Booking and Rides'
}, 
  {
  id: 7,
  q: 'How do you protect my personal information?',
  a: 'We take data privacy seriously. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your data.',
  category: 'Privacy and Data'
}, 
  {
  id: 8,
  q: 'Can I delete my account and personal data?',
  a: `Yes, you can. Contact our customer support, and we'll assist you with the process.`,
  category: 'Privacy and Data'
}, 
  {
  id: 9,
  q: 'Do you share my data with third parties?',
  a: `No, we do not share your data with third parties. Your privacy is important to us.`,
  category: 'Privacy and Data'
}, 
  {
  id: 10,
  q: 'What should I do in case of an issue or complaint?',
  a: `If you have any issues or complaints, please contact our customer support at [Customer Support Email/Phone]. We are available 24/7 to assist you`,
  category: 'General Questions'
}, 
  {
  id: 11,
  q: `How can I stay updated with Rive's news and promotions?`,
  a: `You can follow us on social media platforms like Facebook, Twitter, and Instagram to stay informed about our latest news and promotions.`,
  category: 'General Questions'
}, 
  {
    id: 12,
  q: `Can I partner with Rive for business collaborations?`,
  a: `Yes, we're open to exploring business collaborations and partnerships. Please contact us at [Business Inquiries Email] to discuss opportunities.`,
  category: 'General Questions'
}
];

export default questions;