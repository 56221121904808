import React from "react";
import logo from "../../Images/Admin Logo.svg";
import search from "../../Images/Search.svg";

const TopNav = ({ pageTitle, pageDescription }) => {
	return (
		<>
			<div className="topNav">
				<div className="logos">
					<img src={logo} alt="rive" />
					<p>Rive</p>
				</div>
				<div className="leftSide">
					<div className="namesHead">
						<h1>{pageTitle}</h1>
						<p>{pageDescription}</p>
					</div>

					<div className="sided hide">
						<div className="inputsearch">
							<img src={search} alt="" />
							<input
								type="search"
								name="search"
								id=""
								placeholder="Search"
								className="headsearch"
							/>
						</div>
						<button type="submit" className="adminSearch">
							Search
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TopNav;
